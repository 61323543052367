// import { ReactComponent as YoutubeIcon } from 'assets/UI/Youtube.svg';
// import { FilledButton, StyledSpan } from 'Atoms/buttons/FilledButton';
// import { Icon } from 'Atoms/Icon';
import { contentClassNames, Modal } from 'Atoms/Modal';
import { H1, H2, H3 } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { MailInboxButton } from 'Molecules/buttons/MailInboxButton';
import { ReferenceCloseButton } from 'Molecules/buttons/ReferenceCloseButton';
import { GraduationConfirmationModal } from 'Molecules/modal/GraduationConfirmationModal';
import { StartPageButtons } from 'Molecules/StartPageButtons';
import React, { FC, useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { PromotionStateContext } from 'services/localStorage/PromotionState.provider';
import { ThemeModeContext } from 'services/theme';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';
import { startLinks } from 'types/startPage';

const MainLayoutStyled = styled(MainLayout)`
  padding: 40px 180px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    min-height: calc(100vh - 80px);
  }

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 40px 20px;
  }

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding: 40px 0px;
  }
`;

const H1Styled = styled(H1)`
  margin-top: 27px;
`;

const H3Styled = styled(H3)`
  margin: 50px 0 40px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 40px 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 40px 0;
`;

// const IconStyled = styled(Icon)`
//   margin-right: 10px;
// `;

// const VideoButton = styled(FilledButton)`
//   width: 160px;
//   height: 40px;

//   background: ${props => props.theme.colors.videoButton.background};
//   border-color: ${props => props.theme.colors.videoButton.border};

//   padding: 5px;

//   ${StyledSpan} {
//     color: ${props => props.theme.colors.videoButton.color};
//   }
// `;

const ModalStyled = styled(Modal)`
  .${contentClassNames.base} {
    display: flex;
    aling-items: center;
    justify-content: center;

    max-width: 90%;
    max-height: 80%;

    height: 100%;

    @media (max-width: ${props => props.theme.breakpoints.m}) {
      max-width: 100%;
    }
  }
`;

const CloseButtonStyled = styled(ReferenceCloseButton)`
  top: 0;
  right: 0;
`;

export const Start: FC = () => {
  const { isDarkMode } = useContext(ThemeModeContext);
  const { push } = useHistory();
  const [isMailOpen, setMailOpen] = useState<boolean>(false);
  const [isPromotion, setIsPromotion] = useContextAssert(PromotionStateContext);
  const { isAuthenticated } = useAccountController();

  const [videoModalOpen, setVideoModalOpen] = useState(false);

  return (
    <MainLayoutStyled>
      <Helmet>
        <title>Start - GIDEON</title>
      </Helmet>
      {isPromotion && isAuthenticated && <MailInboxButton setMailOpen={setMailOpen} />}
      <H1Styled color="lightLink" weight="600" size="big" font="Inter">
        Welcome to GIDEON
      </H1Styled>
      <H2 color="input" weight="500" size="smallRegular" font="Inter">
        your go-to resource on Infectious Diseases
      </H2>
      <H3Styled color="exploreLandingTitle" weight="500" font="Quicksand">
        Where would you like to start ?
      </H3Styled>
      <ButtonContainer>
        {/* <VideoButton
          weight="400"
          onClick={() => setVideoModalOpen(true)}
          aria-label="See the 18 min demo video"
        >
          <IconStyled svgComponent={YoutubeIcon} size="smallMedium" />
          18min demo
        </VideoButton> */}
      </ButtonContainer>
      <StartPageButtons isDarkMode={isDarkMode} onClick={to => push(startLinks[to])} />
      <GraduationConfirmationModal
        title="Notification Center"
        isOpen={isMailOpen}
        onClose={() => setMailOpen(false)}
      />
      <ModalStyled isOpen={videoModalOpen} onClose={() => setVideoModalOpen(false)}>
        <CloseButtonStyled onClick={() => setVideoModalOpen(false)} />
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/2FI2GefMfpA"
          title="GIDEON demo video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ModalStyled>
    </MainLayoutStyled>
  );
};
