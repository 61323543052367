import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Span } from 'Atoms/text';
import { H1 } from 'Atoms/text/H';
import { DatePicker } from 'Diagnose/molecules/datePicker/DatePicker';
import { MainLayout } from 'layouts/MainLayout';
import styled from 'styled-components/macro';

export const TrialLayout = styled(MainLayout)`
  padding: 50px 0px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px 0;
  }
`;
export const GraduationTrialLayout = styled(MainLayout)`
  padding: 35px 0px;

  @media (max-width: ${props => props.theme.breakpoints.l}) {
    padding: 40px 0;
  }
`;

export const Title = styled(H1)`
  margin: 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 30px 0 0 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 0 10px;
  }
`;

export const ErrorRow = styled.div`
  width: 450px;
  display: flex;
  flex-direction: row;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
export const GraduationErrorRow = styled.div`
  width: 380px;
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

export const Row = styled.div<{ error?: boolean }>`
  width: 450px;
  display: flex;
  flex-direction: row;
  position: relative;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

export const GraduationRow = styled.div<{ error?: boolean }>`
  width: 380px;
  display: flex;
  flex-direction: row;
  position: relative;

  margin-bottom: ${props => (props.error ? '0' : '20px')};
  &:not(:first-child) {
    margin-top: 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;
export const ButtonRow = styled.div`
  width: 350px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

export const DateInputRow = styled.div`
  width: 380px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:not(:first-child) {
    margin-top: 10px;
  }

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

export const DatePickerStyled = styled(DatePicker)`
  height: 25px;
  width: 100%;

  border: 1px solid ${props => props.theme.colors.text.inactive};
  border-radius: 10px;

  padding: 20px 8px 20px 8px;
  margin: 10px 0 0 0;

  .DateInput_input {
    font: ${props => props.theme.fonts.normalText};
  }
`;

export const SpecialLink = styled(FilledButton)`
  position: relative;
  box-shadow: none;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 350px;
  height: 40px;
  padding: 10px;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 300px;
  }

  ${Span} {
    color: ${props => props.theme.colors.filledButton.special.default.textColor};
  }

  &:hover {
    background: ${props => props.theme.colors.filledButton.special.hover.backgroundColor};
    border: 1px solid ${props => props.theme.colors.filledButton.special.hover.borderColor};

    ${Span} {
      color: ${props => props.theme.colors.filledButton.special.hover.textColor};
    }
  }

  &:focus {
    background: ${props => props.theme.colors.filledButton.special.focus.backgroundColor};
    border: 1px solid ${props => props.theme.colors.filledButton.special.focus.borderColor};

    ${Span} {
      color: ${props => props.theme.colors.filledButton.special.focus.textColor};
    }
  }

  transition: background 0.4s, border 0.4s;
`;
