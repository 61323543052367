import { isNotNull } from 'apiServices/common/guards';
import {
  getDiseaseCountries as getDiseaseCountriesAPI,
  getDiseaseCountriesPublic as getDiseaseCountriesPublicAPI,
} from 'apiServices/Diseases/diseases';
import _ from 'lodash';
import { Props as BreadcrumbProps } from 'Molecules/breadcrumb/Breadcrumb';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { countryCodesISO2, countryCodesISO3 } from 'services/countryCodes';
import { useAccountController } from 'store/accountStore/hooks';
import { useGetCountryList } from 'store/countriesStore/hooks';
import { useGetDiseaseCountries } from 'store/diseaseDetailStore/hooks';
import { useGetDiseaseList } from 'store/diseasesStore/hooks';
import { useGetDrugList } from 'store/drugsStore/hooks';
import { useGetMicrobeList } from 'store/microbesStore/hooks';
import { useGetVaccineList } from 'store/vaccinesStore/hooks';
import {
  breadcrumbBackFactory,
  BreadcrumbLinkLevel,
  breadcrumbMobileLinkFactory,
  BreadcrumbOptions,
  BreadcrumbOptionsMicrobes,
  BreadcrumbRoute,
  breadcrumbSelectFactory,
  BreadcrumbSelectLevel,
  BreadcrumbSelectOption,
  breadcrumbTextFactory,
  BreadcrumbTextLevel,
  RouteContext,
} from 'types/breadcrumb';
import { CountrySelectOption } from 'types/country';
import { DiseaseCountry } from 'types/disease';
import { matchPathExtended } from 'types/pathParams';

import { slugIdExtractor } from './slug/slug.service';
import { useMobile } from './useMobile';

const exploreOptions: BreadcrumbTextLevel = {
  label: 'Exploring',
  type: 'text',
};

const exploreInnerOptions: BreadcrumbSelectLevel = {
  options: [
    {
      value: 'diseases',
      label: 'Diseases',
    },
    {
      value: 'drugs',
      label: 'Drugs',
    },
    {
      value: 'vaccines',
      label: 'Vaccines',
    },
    {
      value: 'pathogens',
      label: 'Pathogens',
    },
    {
      value: 'countries',
      label: 'Countries',
    },
  ],
  type: 'select',
  isSearchable: false,
  onChange: (option, push) => option && push(`/explore/${option.value}`),
  value: values => values[1],
  key: 'explore',
  ariaLabel: 'Explore select',
};

const bacteriaTypeOption: BreadcrumbLinkLevel = {
  label: 'Bacteria',
  path: '/explore/pathogens',
  type: 'link',
};

const yeastsTypeOption: BreadcrumbLinkLevel = {
  label: 'Yeasts',
  path: '/explore/pathogens',
  type: 'link',
};

const virusTypeOption: BreadcrumbLinkLevel = {
  label: 'Viruses',
  path: '/explore/pathogens',
  type: 'link',
};
const parasiteTypeOption: BreadcrumbLinkLevel = {
  label: 'Parasites',
  path: '/explore/pathogens',
  type: 'link',
};
const fungusTypeOption: BreadcrumbLinkLevel = {
  label: 'Fungi',
  path: '/explore/pathogens',
  type: 'link',
};

const mycobacteriaTypeOption: BreadcrumbLinkLevel = {
  label: 'Mycobacteria',
  path: '/explore/pathogens',
  type: 'link',
};

const azListsOption: BreadcrumbLinkLevel = {
  label: 'All A-Z lists',
  path: '/az',
  type: 'link',
};

const visualizeOptions: BreadcrumbTextLevel = {
  label: 'Visualizing',
  type: 'text',
};

const thisYear = new Date().getFullYear();

const visualizeInnerOptions: BreadcrumbSelectLevel = {
  options: [
    {
      value: 'graphs',
      label: 'Epidemiological graphs',
    },
    {
      value: 'disease-outbreaks',
      label: 'Disease outbreaks',
    },
    {
      value: 'vaccine-coverage-maps',
      label: 'Vaccine coverage',
    },
    {
      value: `global-outbreaks-map`,
      label: 'Global outbreaks',
    }
  ],
  type: 'select',
  isSearchable: false,
  onChange: (option, push) => {
    if (option) {
      if (option.value === 'global-outbreaks-map') {
        push(`/visualize/${option.value}/${thisYear}`);
      } else if (option.value === 'disease-outbreaks' || option.value === 'vaccine-coverage-maps') {
        push(`/visualize/${option.value}/start`);
      } else {
        push(`/visualize/${option.value}`);
      }
    }
  },
  value: values => values[1],
  key: 'visualize',
  ariaLabel: 'visualize select',
};

const backLevel = breadcrumbBackFactory();

const defaultProps: BreadcrumbProps = {
  desktopLevels: [exploreOptions, exploreInnerOptions],
  mobileLevels: [],
};

const breadcrumbRoutes: BreadcrumbRoute[] = [
  {
    handle: (_, location) => {
      const match = matchPathExtended(
        ['/explore/diseases', '/explore/drugs', '/explore/vaccines', '/explore/pathogens'],
        location.pathname
      );

      if (!match?.isExact) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (_, location, context) => {
      const match = matchPathExtended(['/explore/countries'], location.pathname);

      if (!match?.isExact || context.isMobile) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/diseases/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const value = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.diseases(),
        isSearchable: true,
        onChange: (option, push) => option && push(option.value),
        value: () => value,
        key: 'disease',
        ariaLabel: 'Disease select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change disease',
        path: '/explore/diseases',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location, context) => {
      const match = matchPathExtended(
        '/explore/diseases/:diseaseSlug/:countrySlug',
        location.pathname
      );

      if (!match?.isExact) {
        return null;
      }

      const diseaseId = slugIdExtractor(match.params.diseaseSlug);
      const countryId = slugIdExtractor(match.params.countrySlug);

      const diseaseLevel = breadcrumbSelectFactory({
        options: options.diseases(),
        isSearchable: true,
        value: () => diseaseId,
        key: 'disease',
        onChange: (option, push, setLoading) => {
          if (!option) {
            return;
          }

          setLoading(true);
          context.getDiseaseCountries(parseInt(option.value)).then(details => {
            const countries = [...details.list, { code: 'outbreaks', hasCountryNote: true }];

            const country = countries.find(c => c.code === countryId);

            if (country?.hasCountryNote) {
              push(`/explore/diseases/${option.value}/${country.code}`);
            } else {
              push(`/explore/diseases/${option.value}`);
            }
            setLoading(false);
          });
        },
        ariaLabel: 'Disease select',
      });

      const diseaseCountries = options.diseaseCountries(diseaseId);

      const sortedDiseaseCountries = diseaseCountries
        .slice(3)
        .sort((a, b) => a.label.localeCompare(b.label));

      const diseaseInnerLevel = breadcrumbSelectFactory({
        options: diseaseCountries.slice(0, 3).concat(sortedDiseaseCountries),
        isSearchable: true,
        value: () => countryId,
        onChange: (option, push) =>
          option && push(`/explore/diseases/${diseaseId}/${option.value}`),
        key: 'diseaseCountry',
        ariaLabel: 'Disease country select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change country note',
        path: `/explore/diseases/${diseaseId}`,
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, diseaseLevel, diseaseInnerLevel],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/drugs/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const drugId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.drugs(),
        isSearchable: true,
        value: () => drugId,
        onChange: (option, push) => option && push(`/explore/drugs/${option.value}`),
        key: 'drug',
        ariaLabel: 'Drug select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change drug',
        path: '/explore/drugs',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/vaccines/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const vaccineId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.vaccines(),
        isSearchable: true,
        value: () => vaccineId,
        onChange: (option, push) => option && push(`/explore/vaccines/${option.value}`),
        key: 'vaccine',
        ariaLabel: 'Vaccine select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change vaccine',
        path: '/explore/vaccines',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/bacteria/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const bacteriaId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().bacteria,
        isSearchable: true,
        value: () => bacteriaId,
        onChange: (option, push) => option && push(`/explore/pathogens/bacteria/${option.value}`),
        key: 'bacteria',
        ariaLabel: 'Bacteria select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, bacteriaTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/mycobacteria/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const microbeId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().mycobacteria,
        isSearchable: true,
        value: () => microbeId,
        onChange: (option, push) =>
          option && push(`/explore/pathogens/mycobacteria/${option.value}`),
        key: 'mycobacteria',
        ariaLabel: 'Mycobacteria select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, mycobacteriaTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/yeasts/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const yeastId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().yeasts,
        isSearchable: true,
        value: () => yeastId,
        onChange: (option, push) => option && push(`/explore/pathogens/yeasts/${option.value}`),
        key: 'yeast',
        ariaLabel: 'Yeast select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, yeastsTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/viruses/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const virusId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().virus,
        isSearchable: true,
        value: () => virusId,
        onChange: (option, push) => option && push(`/explore/pathogens/viruses/${option.value}`),
        key: 'virus',
        ariaLabel: 'Virus select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, virusTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/parasites/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const parasiteId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().parasite,
        isSearchable: true,
        value: () => parasiteId,
        onChange: (option, push) => option && push(`/explore/pathogens/parasites/${option.value}`),
        key: 'parasite',
        ariaLabel: 'Parasite select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, parasiteTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/pathogens/fungi/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const fungusId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.pathogens().fungus,
        isSearchable: true,
        value: () => fungusId,
        onChange: (option, push) => option && push(`/explore/pathogens/fungi/${option.value}`),
        key: 'fungus',
        ariaLabel: 'Fungus select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change pathogen',
        path: '/explore/pathogens',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, fungusTypeOption, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/explore/countries/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const countryId = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.countries(),
        isSearchable: true,
        value: () => countryId,
        onChange: (option, push) => option && push(`/explore/countries/${option.value}`),
        key: 'country',
        ariaLabel: 'Country select',
      });

      const mobileLinkLevel = breadcrumbMobileLinkFactory({
        label: 'Change country',
        path: '/explore/countries',
      });

      return {
        ...defaultProps,
        desktopLevels: [backLevel, ...defaultProps.desktopLevels, level],
        mobileLevels: [backLevel, mobileLinkLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/search', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/az', '/az/*'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel],
        mobileLevels: [backLevel, azListsOption],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/account'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/compare', '/compare/*'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        ...defaultProps,
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (_, location) => {
      const match = matchPathExtended(['/visualize/graphs'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel, ...[visualizeOptions, visualizeInnerOptions]],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/visualize/disease-outbreaks/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const value = slugIdExtractor(match.params.slug);

      const level = breadcrumbSelectFactory({
        options: options.diseases(),
        isSearchable: true,
        onChange: (option, push) => option && push(option.value),
        value: () => value,
        key: 'disease',
        ariaLabel: 'Disease select',
      });

      return {
        desktopLevels: [backLevel, ...[visualizeOptions, visualizeInnerOptions], level],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/visualize/global-outbreaks-map/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel, ...[visualizeOptions, visualizeInnerOptions]],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/visualize/vaccine-coverage-maps/:slug', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel, ...[visualizeOptions, visualizeInnerOptions]],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(
        ['/diagnose/steps', '/diagnose/probability-engine'],
        location.pathname
      );

      if (!match?.isExact) {
        return null;
      }

      const changeMethodTextLevel = breadcrumbTextFactory({
        label: 'Change method',
      });

      const selectLevel = breadcrumbSelectFactory({
        key: 'change-method',
        options: [
          {
            label: 'Step by step',
            value: 'steps',
          },
          {
            label: 'Probability engine',
            value: 'probability-engine',
          },
        ],
        onChange: (option, push) => option && push(option.value),
        value: values => values[1],
        isSearchable: false,
        ariaLabel: 'Diagnose method select',
      });

      return {
        desktopLevels: [backLevel, changeMethodTextLevel, selectLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/lab/bacteria', '/lab/mycobacteria', '/lab/yeasts'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/lab/*/probability-engine'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const changeOrganismTextLevel = breadcrumbTextFactory({
        label: 'Change organism',
      });

      const selectLevel = breadcrumbSelectFactory({
        key: 'change-organism',
        options: [
          {
            label: 'Bacteria',
            value: 'bacteria',
          },
          {
            label: 'Mycobacteria',
            value: 'mycobacteria',
          },
          {
            label: 'Yeasts',
            value: 'yeasts',
          },
        ],
        onChange: (option, push) => option && push(`/lab/${option.value}/probability-engine`),
        value: values => values[1],
        isSearchable: false,
        ariaLabel: 'Lab organism select',
      });

      return {
        desktopLevels: [backLevel, changeOrganismTextLevel, selectLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/lab/*/tree'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      const changeOrganismTextLevel = breadcrumbTextFactory({
        label: 'Change organism',
      });

      const selectLevel = breadcrumbSelectFactory({
        key: 'change-organism',
        options: [
          process.env.REACT_APP_FEATURE_BACTERIA_TREE === 'enabled'
            ? {
                label: 'Bacteria',
                value: 'bacteria',
              }
            : null,
          process.env.REACT_APP_FEATURE_MYCOBACTERIA_TREE === 'enabled'
            ? {
                label: 'Mycobacteria',
                value: 'mycobacteria',
              }
            : null,
          {
            label: 'Yeasts',
            value: 'yeasts',
          },
        ].filter(isNotNull),
        onChange: (option, push) => option && push(`/lab/${option.value}/tree`),
        value: values => values[1],
        isSearchable: false,
        ariaLabel: 'Lab organism select',
      });

      return {
        desktopLevels: [backLevel, changeOrganismTextLevel, selectLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/updates', '/updates/*'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended(['/ebooks', '/ebooks/*'], location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
  {
    handle: (options, location) => {
      const match = matchPathExtended('/sitemap', location.pathname);

      if (!match?.isExact) {
        return null;
      }

      return {
        desktopLevels: [backLevel],
        mobileLevels: [backLevel],
      };
    },
  },
];

const mapCountryToBreadcrumbOption = (c: DiseaseCountry): CountrySelectOption => {
  return {
    label: c.country,
    value: c.code,
    iso2Code: countryCodesISO2[c.country],
    iso3Code: countryCodesISO3[c.country],
    disabled: !c.hasCountryNote,
  };
};

/**
 * useBreadcrumb provides BreadcrumbContainerProps if Breadcrumb component needs to be shown and null otherwise
 */
export const useBreadcrumb = (): BreadcrumbProps | null => {
  const location = useLocation();
  const [props, setProps] = useState<BreadcrumbProps | null>(null);
  const { isAuthenticated } = useAccountController();

  const isMobile = useMobile('m', 'max');

  const getDiseaseCountries = useGetDiseaseCountries();
  const getDiseaseList = useGetDiseaseList();
  const getDrugList = useGetDrugList();
  const getVaccineList = useGetVaccineList();
  const getCountryList = useGetCountryList();
  const getMicrobeList = useGetMicrobeList();

  const getDiseaseInnerOptions = useCallback(
    (id: string): BreadcrumbSelectOption[] => {
      const diseaseId = parseInt(id);

      const [general, otherCountries] = _.partition(
        getDiseaseCountries(diseaseId),
        c => c.code === 'worldwide' || c.code === 'bioterrorism'
      );

      const generalCountries = general.map(mapCountryToBreadcrumbOption);
      const countries = otherCountries.map(mapCountryToBreadcrumbOption);

      return generalCountries.length > 0
        ? [
            ...generalCountries,
            {
              label: 'Outbreaks',
              value: 'outbreaks',
              disabled: false,
            },
            ...countries,
          ]
        : [...generalCountries, ...countries];
    },
    [getDiseaseCountries]
  );

  const getCountries = useCallback(() => {
    const countries = getCountryList().map(v => ({ label: v.name, value: v.id })).filter(el=>el.value!=="G100");

    return [{ label: 'Worldwide', value: 'G100' },{ label: 'Bioterrorism', value: 'bioterrorism' },...countries];
  }, [getCountryList]);

  const getMicrobes = useCallback((): BreadcrumbOptionsMicrobes => {
    const microbes = getMicrobeList();

    return {
      bacteria: microbes.bacteria.map(d => ({ label: d.name, value: d.id.toString() })),
      mycobacteria: microbes.mycobacteria.map(d => ({ label: d.name, value: d.id.toString() })),
      yeasts: microbes.yeasts.map(d => ({ label: d.name, value: d.id.toString() })),
      virus: microbes.viruses.map(d => ({ label: d.name, value: d.id.toString() })).sort((a,b)=>a.label>b.label?1:a.label<b.label?-1:0),
      parasite: microbes.parasites.map(d => ({ label: d.name, value: d.id.toString() })).sort((a,b)=>a.label>b.label?1:a.label<b.label?-1:0),
      fungus: microbes.fungi.map(d => ({ label: d.name, value: d.id.toString() })).sort((a,b)=>a.label.localeCompare(b.label)),
    };
  }, [getMicrobeList]);

  const options = useMemo<BreadcrumbOptions>(
    () => ({
      diseases: () =>
        getDiseaseList().map(d => ({
          label: d.name,
          value: d.id.toString(),
        })),
      drugs: () => getDrugList().map(d => ({ label: d.name, value: d.id.toString() })),
      vaccines: () => getVaccineList().map(v => ({ label: v.name, value: v.id.toString() })),
      countries: getCountries,
      pathogens: getMicrobes,
      diseaseCountries: getDiseaseInnerOptions,
    }),
    [getCountries, getMicrobes, getDiseaseInnerOptions, getDiseaseList, getDrugList, getVaccineList]
  );

  const routeContext = useMemo<RouteContext>(
    () => ({
      getDiseaseCountries: isAuthenticated ? getDiseaseCountriesAPI : getDiseaseCountriesPublicAPI,
      isMobile: isMobile,
    }),
    [isAuthenticated, isMobile]
  );

  useEffect(() => {
    for (const route of breadcrumbRoutes) {
      const result = route.handle(options, location, routeContext);

      if (result) {
        setProps(result);
        return;
      }
    }

    setProps(null);
  }, [setProps, location, options, routeContext]);

  return props;
};
