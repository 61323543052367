import { Loading } from 'store/types';

import { Actions, State } from './provider';

export const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'Classes/LoadInitiated': {
      return {
        ...state,
        [action.payload.type]: Loading,
      };
    }
    case 'Classes/Loaded':
    case 'Classes/LoadFailed': {
      return {
        ...state,
        [action.payload.type]: action.payload.value,
      };
    }
    default: {
      // eslint-disable-next-line
      const never: never = action;
      return state;
    }
  }
};
