import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { DrugSpectrumSection } from 'Molecules/drug/DrugSpectrumSection';
import { DrugSummarySection } from 'Molecules/drug/DrugSummarySection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { TradeNamesSection } from 'Molecules/section/TradeNamesSection';
import { WarningsSection } from 'Molecules/section/WarningsSection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useDrugDetails } from 'store/drugDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  drugId: number;
};

export const Drug: FC<Props> = ({ drugId, location }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showWarnings, setShowWarnings] = useState(true);
  const [showTradeNames, setShowTradeNames] = useState(true);
  const [showSpectrum, setShowSpectrum] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const drug = useDrugDetails(drugId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(drug)) {
    return <Loader />;
  }

  assertIsNotStoreError(drug);

  const sections: RecordLink[] = [
    'Summary',
    'Warnings',
    `Trade names  (${drug.synonymWithReference?.length})`,
    `Spectrum (${drug.spectrum.length + drug.drugClasses.length})`,
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${drug.drug} ${section.toLowerCase()}`,
  }));

  const description = `GIDEON - ${drug.drug}. Mechanism of Action: ${getStringFromDynamicHtml(
    drug.mechanismOfAction
  )}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{drug.drug} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={drug.drug}>
          <RecordLayout>
            <RecordTitle
              title={drug.drug}
              recordType={'drug'}
              buttons={<SmartCompareButton section="drugs" id={drug.code} size="big" showTooltip />}
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(drug.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="drug-summary"
            >
              <DrugSummarySection record={drug} />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showWarnings}
              onCollapse={() => setShowWarnings(!showWarnings)}
              accent="second"
              id="drug-warnings"
            >
              <WarningsSection
                firstColumnTitle="Toxic effects"
                secondColumnTitle="Interactions"
                firstColumnData={drug.toxicEffects.map(x => x.name)}
                secondColumnData={drug.interactions}
              />
            </RecordSection>

            <RecordSection
              link={sections[2]}
              show={showTradeNames}
              onCollapse={() => setShowTradeNames(!showTradeNames)}
              accent="third"
              id="drug-trade-names"
            >
              {drug.synonymWithReference && <TradeNamesSection data={drug.synonymWithReference} />}
            </RecordSection>

            <RecordSection
              link={sections[3]}
              show={showSpectrum}
              onCollapse={() => setShowSpectrum(!showSpectrum)}
              accent="fourth"
              id="drug-spectrum"
            >
              <DrugSpectrumSection spectrum={drug.spectrum} drugClasses={drug.drugClasses} />
            </RecordSection>
            <RecordSection
              link={sections[4]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fifth"
              id="drug-downloads"
            >
              <DownloadsSection id={drugId} category="drugs" labelTitle={drug.drug} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
