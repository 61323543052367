import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { ParasiteFungusSummarySection } from 'Molecules/section/ParasiteFungusSummarySection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useFungusDetailsPublic } from 'store/fungusDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'types/common';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  fungusId: number;
};

export const FungusPublic: FC<Props> = ({ location, fungusId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showCharacteristics, setShowCharacteristics] = useState(true);

  const fungus = useFungusDetailsPublic(fungusId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(fungus)) {
    return <Loader />;
  }
  assertIsNotStoreError(fungus);

  const sections: RecordLink[] = [
    'Summary',
    'Physical Characteristics',
    'Habitat',
    'Lifecycle',
    'Images',
    'Identification notes',
    'Downloads and References',
  ].map(section => ({
    label: section,
    'aria-label': `${fungus.name} ${section.toLowerCase()}`,
    disabled:
      (section === 'Images' && fungus.images === undefined) ||
      (section === 'Physical Characteristics' && fungus.characteristics === null) ||
      (section === 'Habitat' && fungus.habitat === null) ||
      (section === 'Lifecycle' && fungus.lifecycle === null) ||
      (section === 'Identification notes' && fungus.identification_notes === null),
  }));
  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{fungus.name} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={fungus.name}>
          <RecordLayout>
            <RecordTitle
              title={fungus.name}
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(fungus.timestamp)}
            />
            <RecordLinks links={sections} />

            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="virus-summary"
            >
              <ParasiteFungusSummarySection
                diseases={fungus.diseases}
                reservoirs={fungus.reservoirs}
                vehicles={fungus.vehicles}
                vectors={fungus.vectors}
                vaccines={fungus.vaccines}
                drugs={fungus.drugs}
                etymology={fungus.etymology}
              />
            </RecordSection>
            {fungus.characteristics && (
              <RecordSection
                link={sections[1]}
                show={showCharacteristics}
                onCollapse={() => setShowCharacteristics(!showCharacteristics)}
                accent="second"
                id="fungus-characteristics"
              >
                <DynamicHtml content={fungus.characteristics} />
              </RecordSection>
            )}
            <Element name="Habitat">
              <Element name="Lifecycle">
                <Element name="Images">
                  <Element name="Identification notes">
                    <Element name="Downloads and references"></Element>
                    <Paywall />
                  </Element>
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
