import { ReactComponent as Document } from 'assets/Search/Document.svg';
import { ReactComponent as Graph } from 'assets/Search/Graph.svg';
import { ReactComponent as Image } from 'assets/Search/Image.svg';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { H2 } from 'Atoms/text';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';
import { SearchRecordType } from 'types/search';

const SearchResultStyled = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;
  background-color: ${props => props.theme.colors.search.default.backgroundColor};
  border: solid 1px ${props => props.theme.colors.search.default.borderColor};
  box-shadow: 0 2px 2px 0 ${props => props.theme.colors.search.default.boxShadowColor};
  border-radius: 4px;

  &:hover,
  &:focus {
    background-color: ${props => props.theme.colors.search.hover.backgroundColor};
    border: solid 1px ${props => props.theme.colors.search.hover.borderColor};
    box-shadow: 0 2px 2px 0 ${props => props.theme.colors.search.hover.boxShadowColor};
  }

  transition: all 200ms ease;
`;

const IconStyled = styled(Icon)`
  max-height: 30px;
  max-width: 30px;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled(Link)`
  margin-left: 15px;
`;

const TitleHeading = styled(H2)`
  margin: 0;
  text-align: left;
`;

const Description = styled.div`
  margin: 10px 0 0 45px;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 10px 0 0 0px;
  }

  font: ${props => props.theme.fonts.bigText};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    font: ${props => props.theme.fonts.normalText};
  }
`;

const TimeStamp = styled(H2)`
  margin: 3px 0 0 44px;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    margin: 2px 0 0 4px;
  }
  text-align: left;
`;

interface Props {
  className?: string;
  title: DynamicContent;
  description: DynamicContent;
  recordType: SearchRecordType;
  path: string | null;
  showTimeStamp?: string;
}

export const SearchResult: FC<Props> = ({
  className,
  title,
  description,
  recordType,
  path,
  showTimeStamp,
}) => {
  const getIcon = (): FC => {
    switch (recordType) {
      case 'document':
        return Document;
      case 'graph':
        return Graph;
      case 'image':
        return Image;
    }
  };

  const [stripedPath, hash] = path?.split('#') || ['', ''];

  return (
    <SearchResultStyled className={className}>
      <ReferenceProvider>
        <TitleContainer>
          <IconStyled svgComponent={getIcon()} fill="inactive" />
          <Title
            to={{
              pathname: stripedPath,
              hash,
            }}
          >
            <TitleHeading size="regular" font="Exo2" color="darkLink">
              <DynamicHtml content={title} />
            </TitleHeading>
          </Title>
        </TitleContainer>
        {showTimeStamp && (
          <TimeStamp color="timeStamp" size="mediumRegular" font="Exo2">
            {showTimeStamp}
          </TimeStamp>
        )}
        <Description>
          <DynamicHtml content={description} />
        </Description>
      </ReferenceProvider>
    </SearchResultStyled>
  );
};
