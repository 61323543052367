import { reportLog } from 'apiServices/Logs/logs';
import { P } from 'Atoms/text';
import { rgba } from 'polished';
import React, { FC, useEffect } from 'react';
import { useAccountController } from 'store/accountStore/hooks';
import styled from 'styled-components/macro';
import { ParserErrorNode } from 'types/dynamicContent';
import { CustomError, ErrorCodes } from 'types/errorTypes';

import { DynamicHtml } from './DynamicHtml';

interface Props {
  node: ParserErrorNode;
}

const Container = styled.div`
  border: solid 1px ${props => props.theme.colors.text.error};
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 0.5em;
  background-color: ${props => rgba(props.theme.colors.text.error, 0.07)};

  overflow: auto;

  // For firefox
  parsererror sourcetext {
    display: block;
    font-family: monospace;
    white-space: pre;
  }
`;

const StyledPre = styled.pre<{ wrap?: boolean }>`
  white-space: ${props => (props.wrap ? 'pre-wrap' : 'pre')};
`;

export const ParserError: FC<Props> = ({ node }) => {
  const { isAuthenticated } = useAccountController();

  useEffect(() => {
    if (isAuthenticated) {
      const error = new CustomError(
        'Error occured while parsing data from API',
        ErrorCodes.DynamicContentParseError,
        'Error',
        JSON.stringify({ url: window?.location?.href, rawData: node.rawData })
      );

      reportLog({
        message: error.message,
        type: error.type,
        code: error.code?.toString(),
        name: error.code && ErrorCodes[error.code],
        detail: error.detail,
        stackTrace: error?.stack,
      });
    }
  }, [isAuthenticated, node.rawData]);

  if (process.env?.REACT_APP_SHOW_PARSING_ERRORS === 'detailed') {
    return (
      <Container data-cy="dynamic-content-parse-error">
        <P color="error" size="big">
          Error occured while parsing data from API
        </P>

        {node.content && (
          <>
            <P color="error">Browser error</P>
            <DynamicHtml content={[node.content]} />
          </>
        )}

        <P color="error">Raw data from API</P>
        <StyledPre wrap>{node.rawData}</StyledPre>

        <P color="error">Final XML before parsing</P>
        <StyledPre wrap>{node.preparedXML}</StyledPre>
      </Container>
    );
  } else {
    return (
      // <Container data-cy="dynamic-content-parse-error">
      //   <P color="error">
      //     This content has some unexpected error. Our technical team has been notified and will
      //     resolve it soon.
      //   </P>
      // </Container>
      <></>
    );
  }
};
