import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { TradeNamesSection } from 'Molecules/section/TradeNamesSection';
import { VaccineSummarySection } from 'Molecules/section/VaccineSummarySection';
import { WarningsSection } from 'Molecules/section/WarningsSection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import { useVaccineDetails } from 'store/vaccineDetailsStore/hooks';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  vaccineId: number;
};

export const Vaccine: FC<Props> = ({ location, vaccineId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showWarnings, setShowWarnings] = useState(true);
  const [showTradeNames, setShowTradeNames] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const vaccine = useVaccineDetails(vaccineId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(vaccine)) {
    return <Loader />;
  }

  assertIsNotStoreError(vaccine);

  const sections: RecordLink[] = [
    'Summary',
    'Warnings',
    `Trade names  (${vaccine.synonymWithReference?.length})`,
    'Downloads and references',
  ].map(section => ({
    label: section,
    'aria-label': `${vaccine.vaccine} ${section.toLowerCase()}`,
  }));

  const description = `GIDEON - ${vaccine.vaccine}. Mechanism of Action: ${getStringFromDynamicHtml(
    vaccine.mechanismOfAction
  )}`;

  return (
    <ReferenceProvider>
      <Helmet>
        <title>{vaccine.vaccine} - GIDEON</title>
        <meta name="description" content={description} />
      </Helmet>
      <ReferenceLabelContext.Provider value={vaccine.vaccine}>
        <MainLayout>
          <RecordLayout>
            <RecordTitle
              recordType={'vaccine'}
              title={vaccine.vaccine}
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(vaccine.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="vaccine-summary"
            >
              <VaccineSummarySection record={vaccine} />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showWarnings}
              onCollapse={() => setShowWarnings(!showWarnings)}
              accent="second"
              id="vaccine-warning"
            >
              <WarningsSection
                firstColumnTitle="Toxic effects"
                secondColumnTitle="Contraindications"
                firstColumnData={vaccine.toxicEffects.map(x => x.name)}
                secondColumnData={vaccine.contraindications.map(x => x.name)}
              />
            </RecordSection>
            <RecordSection
              link={sections[2]}
              show={showTradeNames}
              onCollapse={() => setShowTradeNames(!showTradeNames)}
              accent="fourth"
              id="vaccine-trade-names"
            >
              {vaccine.synonymWithReference && (
                <TradeNamesSection data={vaccine.synonymWithReference} />
              )}
            </RecordSection>
            <RecordSection
              link={sections[3]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fifth"
              id="vaccine-downloads"
            >
              <DownloadsSection id={vaccineId} category="vaccines" labelTitle={vaccine.vaccine} />
            </RecordSection>
          </RecordLayout>
        </MainLayout>
      </ReferenceLabelContext.Provider>
    </ReferenceProvider>
  );
};
