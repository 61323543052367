import { Link } from 'Atoms/links/Link';
import moment from 'moment';
import React, { ReactNode } from 'react';
import { LatestOutbreak } from 'types/countryLatestOutbreaks';
import { formatNumber } from 'utils/formatNumber';

const getDiseaseLatestOutbreak = (
  diseaseId: string | number,
  latestOutbreaks: LatestOutbreak[]
): LatestOutbreak | null => {
  const outbreak = latestOutbreaks.find(x => x.diseaseCode.toString() === diseaseId.toString());
  if (!outbreak || outbreak.start === null) {
    return null;
  }

  return outbreak;
};

export const getLatestOutbreakLabel = (
  diseaseId: string | number,
  latestOutbreaks?: LatestOutbreak[]
): string | undefined => {
  const outbreak = latestOutbreaks ? getDiseaseLatestOutbreak(diseaseId, latestOutbreaks) : null;

  if (!outbreak) {
    return undefined;
  }

  return `Latest outbreak: ${outbreak.start}${outbreak.end ? ` - ${outbreak.end}` : ''} ${
    outbreak.cases ? `(${formatNumber(outbreak.cases)} cases)` : ''
  }`;
};

export const getTimeStamp = (timestamp: number | undefined) => {
  if (!timestamp) {
    return undefined;
  }
  if (timestamp <= 1718841599) {
    //  comparing with epoch time of June 19, 2024 at 23:59:59
    return `Last updated before June 19, 2024 `;
  }

  return `Last updated: ${moment.unix(timestamp).format('D MMMM YYYY')}`;
};

export const getLatestOutbreakBioterrorismLabel = (
  diseaseId: string | number,
  latestOutbreaks?: LatestOutbreak[]
): ReactNode => {
  const outbreak = latestOutbreaks ? getDiseaseLatestOutbreak(diseaseId, latestOutbreaks) : null;

  if (!outbreak) {
    return undefined;
  }

  return (
    <>
      Latest outbreak:{' '}
      <Link to={`/explore/diseases/${outbreak.diseaseCode}/${outbreak.countryCode}`}>
        {outbreak.country}
      </Link>
      {', '}
      <Link
        to={{
          pathname: `/explore/diseases/${outbreak.diseaseCode}/outbreaks`,
          search: `year=${outbreak.start}`,
        }}
      >
        {outbreak.start}
      </Link>{' '}
      {outbreak.end && (
        <>
          -{' '}
          <Link
            to={{
              pathname: `/explore/diseases/${outbreak.diseaseCode}/outbreaks`,
              search: `year=${outbreak.end}`,
            }}
          >
            {outbreak.end}
          </Link>
        </>
      )}{' '}
      {outbreak.cases ? ` (${formatNumber(outbreak.cases)} cases)` : ''}
    </>
  );
};
