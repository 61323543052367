import { Props as TabProps } from 'Molecules/buttons/Tab';
import React, { FC, ReactElement } from 'react';
import { useLocation } from 'react-router-dom';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
import { TabSection as Section } from 'types/tab';

export const TabsContainer = styled.nav`
  position: relative;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid ${props => props.theme.colors.tab.border};
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

export const Slider = styled.div`
  background-color: transparent;
  position: absolute;
  bottom: 0;
  border-bottom: 5px solid ${props => props.theme.colors.tab.borderActive};
  transition: all 0.6s;
  width: 182px;
`;

interface Props<T extends string, S extends Section<T>> {
  className?: string;
  sections: S[];
  path: string;
  Tab: FC<TabProps>;
  onClick?: (section: S) => void;
}

export const Tabs = <T extends string, S extends Section<T>>({
  className,
  sections,
  path,
  Tab,
  onClick,
}: Props<T, S>): ReactElement => {
  const { push } = usePushState();
  const { pathname } = useLocation();
  const activeSection = pathname.split('/')[2];

  const onClickWrapper = (section: S): void => {
    push(`${path}/${section.slug}`);
    onClick && onClick(section);
  };

  return (
    <TabsContainer className={className} aria-label="compare-tabs-navbar">
      {sections.map((section, i) => (
        <Tab
          name={section.name}
          icon={section.icon}
          key={i}
          onClick={() => onClickWrapper(section)}
          active={activeSection === section.slug}
          count={section.count}
        />
      ))}
      <Slider />
    </TabsContainer>
  );
};
