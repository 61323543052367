import { getCountriesSynonyms } from 'apiServices/Country/country';
import { debounce } from 'lodash';
import { useMemo } from 'react';
import { countryCodesISO2, countryCodesISO3 } from 'services/countryCodes';
import { useAccountController } from 'store/accountStore/hooks';
import { useCountriesList } from 'store/countriesStore/hooks';
import { CountrySelectOption } from 'types/country';
import { Country } from 'types/country';
import { getCountryOptionSynonymImage } from 'utils/customCountryCodes';
import { mapCountriesToSelectOptions } from 'utils/mapCountriesToSelectOptions';

const debouncedLoadOptions = debounce(
  (
    loadOption: () => Promise<CountrySelectOption[]>,
    callback: (options: CountrySelectOption[]) => void
  ) => loadOption().then(callback),
  300
);

interface AdditionalOptions {
  countries?: Country[];
  mappedSelectOptions?: CountrySelectOption[];
}

interface ReturnType {
  countriesOptions: CountrySelectOption[];
  loadCountriesOptions: (inputValue: string) => Promise<CountrySelectOption[]>;
}

export const useCountrySelectOptions = (
  additionalOptions: AdditionalOptions = { countries: [] }
): ReturnType => {
  const countries = useCountriesList();
  const { isAuthenticated } = useAccountController();

  const countriesOptions: CountrySelectOption[] = useMemo(() => {
    if (additionalOptions?.mappedSelectOptions) {
      return additionalOptions?.mappedSelectOptions;
    } else if (additionalOptions?.countries) {
      return mapCountriesToSelectOptions([
        ...additionalOptions?.countries,
        ...countries.filter(
          country => !additionalOptions.countries?.some(el => el.id === country.id)
        ),
      ]);
    }

    return mapCountriesToSelectOptions([...countries]);
  }, [additionalOptions?.countries, additionalOptions?.mappedSelectOptions, countries]);

  const loadCountrySynonymOptions = async (inputValue: string): Promise<CountrySelectOption[]> => {
    const synonyms = await getCountriesSynonyms(inputValue);

    return synonyms.map(synonym => {
      const originalCountry = countriesOptions.find(country => country.value === synonym.id);

      return {
        label: synonym.name,
        value: synonym.id.toString(),
        synonymOf: originalCountry?.label,
        iso2Code: countryCodesISO2[synonym.name],
        iso3Code: countryCodesISO3[synonym.name],
        imageUrl: getCountryOptionSynonymImage(synonym.name, originalCountry?.label),
        disabled: originalCountry?.disabled,
      };
    });
  };

  const loadCountriesOptions = (inputValue: string): Promise<CountrySelectOption[]> =>
    new Promise<CountrySelectOption[]>(resolve =>
      debouncedLoadOptions(async () => {
        if (inputValue.length < 3) {
          return countriesOptions;
        }

        const synonyms = isAuthenticated ? await loadCountrySynonymOptions(inputValue) : [];

        return countriesOptions.concat(synonyms);
      }, resolve)
    );

  return { countriesOptions, loadCountriesOptions };
};
