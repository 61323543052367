import {
  Action,
  ComparisonSection,
  ComparisonSectionKeys,
  defaultInitialState,
  State,
} from './provider';

const addToListIfNotExists = <T>(list: T[], item: T): T[] => {
  return list.indexOf(item) === -1 ? [...list, item] : list;
};

const removeFromListIfExists = <T>(list: T[], item: T): T[] => {
  const index = list.indexOf(item);
  return index === -1 ? list : [...list.slice(0, index), ...list.slice(index + 1)];
};

const updateStateField = <K extends ComparisonSectionKeys, T extends ComparisonSection[K]>(
  state: State,
  fieldName: K,
  newValue: T
): State => {
  return state[fieldName] === newValue
    ? state
    : {
        ...state,
        [fieldName]: newValue,
      };
};

const AddToField = <K extends ComparisonSectionKeys, T extends ComparisonSection[K][number]>(
  state: State,
  fieldName: K,
  newValue: T
): State => updateStateField(state, fieldName, addToListIfNotExists(state[fieldName], newValue));

const removeFromField = <K extends ComparisonSectionKeys, T extends ComparisonSection[K][number]>(
  state: State,
  fieldName: K,
  newValue: T
): State => updateStateField(state, fieldName, removeFromListIfExists(state[fieldName], newValue));

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'IrrelevantDisease/AddToList': {
      const newState = AddToField(state, action.payload.section, action.payload.id);

      newState.activeSection = action.payload.section;

      return newState;
    }
    case 'IrrelevantDisease/RemoveFromList': {
      return removeFromField(state, action.payload.section, action.payload.id);
    }
    case 'IrrelevantDisease/ClearList': {
      return { ...state, [action.payload]: [] };
    }
    case 'IrrelevantDisease/SetActiveSection': {
      return { ...state, activeSection: action.payload };
    }
    case 'IrrelevantDisease/Reset': {
      return defaultInitialState;
    }
    default: {
      const _ignore: never = action; // check if all cases are handled
      return state;
    }
  }
};
