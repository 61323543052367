import { AnimatedRow } from 'types/animated';
import { CellData } from 'types/table';

export interface DiseaseCell extends CellData {
  type: 'Disease';
  hasBioterrorism?: boolean;
  hasCompare?: boolean;
  value: number;
  label: string;
  is_excluded:number;
}

export interface ProbabilityCell extends CellData {
  type: 'Probability';
  hasProgressBar?: boolean;
  value: number;
}

export interface Countries {
  imageUrl?: string;
  darkImageUrl?: string;
  id: string;
}

export interface MoreInfoCell extends CellData {
  type: 'MoreInfo';
  hasCompare?: boolean;
  value: number;
  countries: Countries[];
}

export type DiagnosisRow = [DiseaseCell, ProbabilityCell, MoreInfoCell | undefined];

export type DiagnosisRowVerticalOffset = AnimatedRow<DiagnosisRow>;

export type ColumnHeader = {
  label: string;
  labelMobile?: string;
  isSortable?: boolean;
};

export const isDisaseCell = (
  value: DiseaseCell | ProbabilityCell | MoreInfoCell | undefined
): value is DiseaseCell => {
  return !!value && value.type === 'Disease';
};

export const isProbabilityCell = (
  value: DiseaseCell | ProbabilityCell | MoreInfoCell | undefined
): value is ProbabilityCell => {
  return !!value && value.type === 'Probability';
};
