import { ButtonProps, sizes } from 'Atoms/buttons/Button';
import { Icon } from 'Atoms/Icon';
import { omit } from 'lodash';
import React, { forwardRef } from 'react';
import styled, { css, DefaultTheme } from 'styled-components/macro';

export interface Props extends ButtonProps {
  padding?: keyof typeof paddings;
  active?: boolean;
  color?: keyof DefaultTheme['colors']['filledButton'];
}

type StyleProps = Pick<Props, 'color'>;

export const StyledSpan = styled.span<StyleProps>`
  width: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${props => props.theme.colors.filledButton[props.color || 'default'].default.textColor};
  ${Icon} {
    fill: ${props => props.theme.colors.filledButton[props.color || 'default'].default.textColor};
  }
`;

const paddings = {
  none: '0',
  small: '8px',
  normal: '14px',
};

const FilledButtonBase = forwardRef<HTMLButtonElement, Props>(
  ({ className, children, ...restProps }, ref) => (
    <button className={className} {...omit(restProps, ['color', 'active'])} ref={ref}>
      <StyledSpan color={restProps.color}>{children}</StyledSpan>
    </button>
  )
);

const activeStateStyles = css<Props>`
  border-color: ${props =>
    props.theme.colors.filledButton[props.color || 'default'].active.borderColor};
  ${StyledSpan} {
    color: ${props => props.theme.colors.filledButton[props.color || 'default'].active.textColor};
    ${Icon} {
      fill: ${props => props.theme.colors.filledButton[props.color || 'default'].active.textColor};
    }
  }
  box-shadow: unset;

  &:before {
    width: 140%;
    padding-top: 140%;
    transition: all 0.5s ease-out;
  }
`;

export const FilledButton = styled(FilledButtonBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font: ${props => props.theme.fonts.normalText};
  font-weight: ${props => props.weight || '500'};
  padding: ${props => (props.padding ? paddings[props.padding] : paddings.normal)};
  width: 100%;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-width: 0;
  border-radius: 5px;
  border: 1px solid
    ${props => props.theme.colors.filledButton[props.color || 'default'].default.borderColor};
  box-shadow: ${props =>
    props.theme.colors.filledButton[props.color || 'default'].default.boxShadow};
  background: ${props =>
    props.theme.colors.filledButton[props.color || 'default'].default.backgroundColor};

  transition-duration: 0.4s;
  transition-property: background, color, border, box-shadow, opacity;

  ${StyledSpan} {
    font-size: ${props => sizes[props.size || 'normal'].desktop};
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 0;
    padding-top: 0;
    border-radius: 100%;
    background: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].active.backgroundColor};
  }

  &:focus:enabled {
    border: 1px solid
      ${props => props.theme.colors.filledButton[props.color || 'default'].focus.borderColor};
    background: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].focus.backgroundColor};
    box-shadow: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].focus.boxShadow};
    outline: 2px solid
      ${props => props.theme.colors.filledButton[props.color || 'default'].focus.outline};
    ${StyledSpan} {
      color: ${props => props.theme.colors.filledButton[props.color || 'default'].focus.textColor};
      ${Icon} {
        fill: ${props => props.theme.colors.filledButton[props.color || 'default'].focus.textColor};
      }
    }
  }

  &:hover:enabled {
    background: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.backgroundColor};
    border-color: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.borderColor};
    ${StyledSpan} {
      color: ${props => props.theme.colors.filledButton[props.color || 'default'].hover.textColor};
      ${Icon} {
        fill: ${props => props.theme.colors.filledButton[props.color || 'default'].hover.textColor};
      }
    }
    box-shadow: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.boxShadow};
  }

  &.accessibility-button {
    &:hover:enabled {
      background: ${props =>
        props.theme.colors.filledButton[props.color || 'default'].default.backgroundColor};
      border: 1px solid
        ${props => props.theme.colors.filledButton[props.color || 'default'].default.borderColor};
      box-shadow: ${props =>
        props.theme.colors.filledButton[props.color || 'default'].default.boxShadow};

      ${StyledSpan} {
        color: ${props =>
          props.theme.colors.filledButton[props.color || 'default'].default.textColor};
        ${Icon} {
          fill: ${props =>
            props.theme.colors.filledButton[props.color || 'default'].default.textColor};
        }
      }
    }
  }

  &.toggled {
    background: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.backgroundColor} !important;
    border-color: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.borderColor} !important;
    ${StyledSpan} {
      color: ${props =>
        props.theme.colors.filledButton[props.color || 'default'].hover.textColor} !important;
      ${Icon} {
        fill: ${props =>
          props.theme.colors.filledButton[props.color || 'default'].hover.textColor} !important;
      }
    }
    box-shadow: ${props =>
      props.theme.colors.filledButton[props.color || 'default'].hover.boxShadow} !important;
  }

  &:active:enabled {
    ${activeStateStyles}
  }

  ${props => props.active && activeStateStyles}

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

// modified the focus property to show the background color by just ommiting it and, if email is sent cursor will be no-drop to avoid multiple API request to sent magic link
export const ButtonWithBackground = styled(FilledButton)`
  &:focus:enabled {
  }
  &:disabled {
    opacity: 1;
    cursor: no-drop;
  }
`;
export const RemoveFilledButton = styled(FilledButton)`
  background: #666666 !important;
  border: none !important;
  &:hover:enabled {
    background: #f44336 !important;
  }
`;
export const UndoFilledButton = styled(FilledButton)``;
export const ClaimAndRejectButton = styled(FilledButton)`
  ${StyledSpan} {
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      font-size: 12px;
    }
  }
`;
