import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { DynamicContent } from 'types/dynamicContent';
import { Image } from 'types/image';
import { Reference } from 'types/reference';
import { CaseSeries, Crossborders, Outbreaks, Survey } from 'types/surveyTables';

import {
  CaseSeriesDTO,
  CrossborderEventDTO,
  ImageDTO,
  OutbreaksDTO,
  ReferenceDTO,
  SurveyDTO,
} from './DTO';

export const mapReferenceDTO = (reference: ReferenceDTO): Reference => ({
  referenceIdentifier: reference.reference_number,
  citation: reference.citation,
  shortCitation: reference.short_citation,
  href: reference.href,
});

const getNotesData = (notes: string | null, references: ReferenceDTO[]): DynamicContent => {
  if (notes && notes.length > 0) {
    if (references && references.length > 0) {
      return parseDynamicContent(
        notes + references.map(ref => `{${ref.reference_number}}`).join(' ')
      );
    }
    return parseDynamicContent(notes);
  }
  if (references && references.length > 0) {
    return parseDynamicContent(references.map(ref => `{${ref.reference_number}}`).join(' '));
  }

  return [{ type: 'text', value: ''}];
};

export const mapSurveyDTO = (x: SurveyDTO): Survey => ({
  tableId:x.id,
  disease: x.disease || null,
  surveyStart: x.survey_start,
  surveyEnd: x.survey_end,
  publicationYear: x.publication_year,
  region: x.region,
  population: x.population,
  percent1: x.percent1,
  percent2: x.percent2,
  pathogens: x.pathogens.length ? parseDynamicContent(x.pathogens.map(el=>el+'\n').join(' ')): null,
  testTypes: x.test_types.length ? parseDynamicContent(x.test_types.map(el=>el+'\n').join(' ')): null,
  reviewTypes: x.review_types.length ? parseDynamicContent(x.review_types.map(el=>el+'\n').join(' ')): null,
  organismEntities: x.organism_entities.length ? parseDynamicContent(x.organism_entities.map(el=>el+'\n').join(' ')): null,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
  surveyType: x.survey_type || null
});

export const mapCrossbordersDTO = (x: CrossborderEventDTO): Crossborders => ({
  tableId:x.id,
  diseaseCode: x.disease_code,
  startYear: x.start_year,
  endYear: x.end_year,
  publicationYear: x.publication_year,
  acquisitionCountry: x.acquisition_country,
  originationCountry: x.origination_country,
  setting: x.setting,
  cases: x.cases,
  deaths: x.deaths,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
});

export const mapOutbreaksDTO = (x: OutbreaksDTO): Outbreaks => ({
  tableId:x.id,
  hashId:x.hash_id,
  outbreakStart: x.outbreak_start,
  outbreakEnd: x.outbreak_end,
  publicationYear: x.publication_year,
  region: x.region,
  vehicle: x.vehicle,
  setting: x.setting,
  population: x.population,
  cases: x.cases,
  deaths: x.deaths,
  animalpopulation: x.animalpopulation,
  animalcases: x.animalcases,
  animaldeaths: x.animaldeaths,
  pathogen: x.pathogen ? parseDynamicContent(x.pathogen) : null,
  clinical: x.clinical,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO),
});

export const mapImageDTO = ({
  image_file_name: imageFileName,
  alt_text: altText,
  ...rest
}: ImageDTO): Image => ({
  imageFileName,
  altText,
  ...rest,
});

export const mapCaseSeriesDTO = (x: CaseSeriesDTO): CaseSeries => ({
  tableId:x.id,
  startYear: x.start_year,
  endYear: x.end_year,
  publicationYear: x.publication_year,
  region: x.region,
  population: x.population,
  setting: x.setting,
  parameter: x.parameter,
  cases: x.cases,
  deaths: x.deaths,
  notes: getNotesData(x.notes, x.references),
  references: x.references.map(mapReferenceDTO)
});