import { PhenotypicClass } from 'Lab/types/phenotypicClass';
import { StoreError } from 'store/storeError';
import { storeFactory } from 'store/storeFactory';
import { Action as ActionWithoutPayload, ActionWithPayload, Resource } from 'store/types';
import { MicrobeType } from 'types/microbeDetails';

import { reducer } from './reducer';

export type Actions =
  | ActionWithPayload<'Classes/LoadInitiated',{ type: MicrobeType }>
  | ActionWithPayload<'Classes/Loaded', {type:MicrobeType,value:PhenotypicClass[]}>
  | ActionWithPayload<'Classes/LoadFailed', { type: MicrobeType; value: StoreError }>;

export type State = Record<MicrobeType, Resource<PhenotypicClass[]> | null>;

  const initialState: State = {
    bacteria: null,
    mycobacteria: null,
    yeasts: null,
  };

export const {
  dispatchContext: PhenotypicClassDispatchContext,
  stateContext: PhenotypicClassStateContext,
  provider: PhenotypicClassStoreProvider,
} = storeFactory(reducer, initialState);
