import { Moment } from 'moment';
import { ReactNode } from 'react';
import { ActionWithPayload } from 'store/types';

type StringField =
  | 'firstName'
  | 'lastName'
  | 'email'
  |'graduationDate'

type FieldName = StringField;

interface stateType{
  firstName:string;
  lastName:string;
  email:string;
  graduationDate:Moment|null
}

type ErrorFieldName = FieldName | 'form' ;

export type SetValueAction<T extends FieldName> = { field: T; value: State[T] };

export type Action =
  | ActionWithPayload<
      'SignUp/Set/Value',
      SetValueAction<StringField>
    >
  | ActionWithPayload<'SignUp/Set/Errors', State['errors']>
  | ActionWithPayload<'SignUp/Set/Error', { field: ErrorFieldName; value: ReactNode | null }>;

export type State = stateType&
  {
    errors: Record<ErrorFieldName, ReactNode | null>;
  };

export const initialState: State = {
  firstName: '',
  lastName: '',
  email: '',
  graduationDate:null,
  errors: {
    firstName: null,
    lastName: null,
    email: null,
    form: null,
    graduationDate:null
  },
};
