import { Loader } from 'Atoms/Loader';
import { SurveyTable } from 'Organisms/SurveyTable';
import React, { FC, useState } from 'react';
import { useCountrySeroprevalenceSurveys } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';

const footnote1 = '* indicates publication year (not necessarily year of survey)';

const surveyColumns = [
  { name: 'Survey Type', display: 'Survey Type', show: true },
  { name: 'Years', display: 'Years', show: true },
  { name: 'Region', display: 'Region', show: true },
  { name: 'Focus', display: 'Focus', show: true },
  { name: '%', display: '%', show: true },
  { name: 'Pathogen', display: 'Pathogen', show: false },
  { name: 'Test Type', display: 'Test Type', show: false },
  { name: 'Review Type', display: 'Review Type', show: false },
  { name: 'Organism Entity', display: 'Organism Entity', show: false },
  { name: 'Notes', display: 'Notes', show: true },
];

interface Props {
  diseaseId: number;
  countryId: string;
  title?: string;
  subTitle?: string;
}

export const SeroprevalenceSurveys: FC<Props> = ({ diseaseId, countryId, title, subTitle }) => {
  const [extraColumns, setExtraColumns] = useState(JSON.parse(JSON.stringify(surveyColumns)));

  const outbreaksData = useCountrySeroprevalenceSurveys(diseaseId, countryId);
  assertIsNotStoreError(outbreaksData);

  const addExtraColumn = (col: string): void => {
    const tempColumns = [...extraColumns];
    const index = tempColumns.findIndex(temp => temp.name === col);
    if (index > -1) {
      tempColumns[index].show = true;
      setExtraColumns(tempColumns);
    }
  };

  if (isLoading(outbreaksData)) {
    return <Loader />;
  }

  if (!outbreaksData || outbreaksData.length === 0) {
    return <></>;
  }

  return (
    <SurveyTable
      tableType="seroprevalence"
      surveyData={outbreaksData}
      footnote1={footnote1}
      title={title}
      subTitle={subTitle}
      extraColumns={extraColumns}
      addExtraColumn={addExtraColumn}
    />
  );
};
