import { ImageDTO, ReferenceDTO } from 'apiServices/common/DTO';
import { mapImageDTO } from 'apiServices/common/mappers';
import { SimpleDisease as SimpleDiseasesDTO, VaccineDTO } from 'apiServices/Diseases/DTO';
import { parseDynamicContent } from 'services/parser/dynamicContentParser';
import { Timestamp } from 'types/common';
import { Vaccine } from 'types/disease';
import {
  DrugSusceptibility,
  MicrobeDetails,
  MicrobeDetailsPublic,
  ParasiteFungusDetails,
  PathogenEntity,
  Phenotype,
  Reservoir_Vector_Vehicle_WithReference,
  VirusDetails,
} from 'types/microbeDetails';
import { SimpleMicrobe } from 'types/simpleMicrobe';
import { parseEtymologyData } from 'utils/parseEtymologyData';
import { replaceCaseInsensitive } from 'utils/replacer';

import {
  BacteriaDetailsDTO,
  BacteriaDetailsPublicDTO,
  DrugSusceptibilityDTO,
  MycobacteriaDetailsDTO,
  MycobacteriaDetailsPublicDTO,
  ParasiteFungusDetailDTO,
  PathogenReservoir,
  PathogenVector,
  PathogenVehicle,
  PhenotypeDTO,
  SimpleBacteriaDTO,
  SimpleFungusDTO,
  SimpleMycobacteriaDTO,
  SimpleParasiteDTO,
  SimpleVirusDTO,
  SimpleYeastDTO,
  VirusDetailDTO,
  YeastsDetailsDTO,
  YeastsDetailsPublicDTO,
} from './DTO';


export const mapDrugSusceptibilityDTO = (x: DrugSusceptibilityDTO): DrugSusceptibility => ({
  code: x.pathogen_code,
  pathogen: x.pathogen,
  isDrugClass: x.is_drug_class,
});

export const mapVaccineDTO = (vaccine: VaccineDTO): Vaccine => ({
  code: vaccine.vaccine_code,
  vaccine: vaccine.vaccine,
});

export const mapPhenotypeDTO = (x: PhenotypeDTO): Phenotype => ({
  code: x.phenotype_code,
  percent: x.percent,
  phenotype: x.phenotype,
});

const addDiseaseLinks = (diseaseText: string, diseases: SimpleDiseasesDTO[]): string => {
  const replacer = diseases.reduce((acc, disease) => {
    return {
      ...acc,
      [disease.disease]: `<gdn-link type="diseases" id="${disease.disease_code}">${disease.disease}</gdn-link>`,
    };
  }, {});

  return replaceCaseInsensitive(diseaseText, replacer);
};

export const mapBacteriaDetailsDTO = (
  x: BacteriaDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[],
  Timestamp:Timestamp
): MicrobeDetails => ({
  type: 'bacteria',
  code: x.bacteria_code,
  name: x.bacteria,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology: x.ecology?parseDynamicContent(addDiseaseLinks(x.ecology, x.diseases)):null,
  synonyms: x.synonyms,
  synonymWithReference:x.synonyms_with_references?.length
  ? x.synonyms_with_references.map(item => ({
      synonym: item.synonym,
      references: item.synonym && item.references.length? parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' '))
        : null,
    }))
  : null,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
  etymology: (x.etymology && x.etymology.length > 0) ? parseEtymologyData(x.etymology) : undefined,
  timestamp:Timestamp,
  associated_diseases:x.diseases.map(m=>({...m,references:m.references?.length> 0 ? parseDynamicContent(m.references.map(ref => `{${ref.reference_number}}`).join(' ')) : []})).sort((a,b)=>a.disease.localeCompare(b.disease)),
});

export const mapBacteriaDetailsPublicDTO = (x: BacteriaDetailsPublicDTO): MicrobeDetailsPublic => ({
  code: x.bacteria_code,
  name: x.bacteria,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

export const mapMycobacteriaDetailsDTO = (
  x: MycobacteriaDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[],
  Timestamp:Timestamp
): MicrobeDetails => ({
  type: 'mycobacteria',
  code: x.mycobacteria_code,
  name: x.mycobacteria,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology:  x.ecology?parseDynamicContent(addDiseaseLinks(x.ecology, x.diseases)):null,
  synonyms: x.synonyms,
  synonymWithReference:x.synonyms_with_references?.length
  ? x.synonyms_with_references.map(item => ({
      synonym: item.synonym,
      references: item.synonym && item.references.length? parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' '))
        : null,
    }))
  : null,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
  etymology: (x.etymology && x.etymology.length > 0) ? parseEtymologyData(x.etymology) : undefined,
  timestamp:Timestamp,
  associated_diseases:x.diseases.map(m=>({...m,references:m.references?.length> 0 ? parseDynamicContent(m.references.map(ref => `{${ref.reference_number}}`).join(' ')) : []})).sort((a,b)=>a.disease.localeCompare(b.disease)),
});

export const mapMycobacteriaDetailsPublicDTO = (
  x: MycobacteriaDetailsPublicDTO
): MicrobeDetailsPublic => ({
  code: x.mycobacteria_code,
  name: x.mycobacteria,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

export const mapYeastDetailsDTO = (
  x: YeastsDetailsDTO,
  phenotype: PhenotypeDTO[],
  images: ImageDTO[],
  Timestamp:Timestamp
): MicrobeDetails => ({
  type: 'yeasts',
  code: x.yeast_code,
  name: x.yeast,
  drugSusceptibility: x.drug_susceptibility
    ? x.drug_susceptibility.map(mapDrugSusceptibilityDTO)
    : [],
  vaccines: x.vaccines ? x.vaccines.map(mapVaccineDTO) : [],
  notes: parseDynamicContent(x.notes || ''),
  ecology: x.ecology?parseDynamicContent(addDiseaseLinks(x.ecology, x.diseases)):null,
  synonyms: x.synonyms,
  synonymWithReference:x.synonyms_with_references?.length
  ? x.synonyms_with_references.map(item => ({
      synonym: item.synonym,
      references: item.synonym && item.references.length? parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' '))
        : null,
    }))
  : null,
  phenotype: phenotype.map(mapPhenotypeDTO),
  images: images.map(mapImageDTO),
  etymology: (x.etymology && x.etymology.length > 0) ? parseEtymologyData(x.etymology) : undefined,
  timestamp:Timestamp,
  associated_diseases:x.diseases.map(m=>({...m,references:m.references?.length> 0 ? parseDynamicContent(m.references.map(ref => `{${ref.reference_number}}`).join(' ')) : []})).sort((a,b)=>a.disease.localeCompare(b.disease)),
});

export const mapYeastDetailsPublicDTO = (x: YeastsDetailsPublicDTO): MicrobeDetailsPublic => ({
  code: x.yeast_code,
  name: x.yeast,
  notes: parseDynamicContent(x.notes || ''),
  ecology: parseDynamicContent(x.ecology),
});

export const transformPathogenEntities = (
  entities: PathogenVehicle[] | PathogenVector[] | PathogenReservoir[],
  classField: 'vehicle_class' | 'vector_class' | 'reservoir_class',
  entityField: 'vehicle_entity' | 'vector_entity' | 'reservoir_entity',
  entityLatinField: 'vehicle_entity_latin' | 'vector_entity_latin' | 'reservoir_entity_latin'
): Reservoir_Vector_Vehicle_WithReference[] => {
  // Ensure entities is a valid array before proceeding
  if (!Array.isArray(entities)) {
    throw new Error('Invalid entities input: Expected an array.');
  }

  const transformed = (entities as any[]).reduce((acc: Reservoir_Vector_Vehicle_WithReference[], item: any) => {
    const existingClass = acc.find((group) => group.class === item[classField]);

    const entityData: PathogenEntity = {
      entity: item[entityField],
      entity_latin: item[entityLatinField],
      references: item.references.length
        ? parseDynamicContent(item.references.map((ref: ReferenceDTO) => `{${ref.reference_number}}`).join(' '))
        : [],
    };

    if (existingClass) {
      existingClass.EntityArr.push(entityData);
    } else {
      acc.push({
        class: item[classField],
        isEntityLatin: Boolean(item[entityLatinField] && item[entityField]),
        EntityArr: [entityData],
      });
    }

    return acc;
  }, []);

  // Sort the entities in each group and the groups themselves
  transformed.forEach((group) => {
    group.EntityArr.sort((a, b) => (a.entity && b.entity ? a.entity.localeCompare(b.entity):0));
  });

  return transformed.sort((a, b) => a.class.localeCompare(b.class));
};


export const pathogenReservoirDTO = (reservoirs: PathogenReservoir[]): Reservoir_Vector_Vehicle_WithReference[] =>
  transformPathogenEntities(reservoirs, 'reservoir_class', 'reservoir_entity', 'reservoir_entity_latin');

export const pathogenVehicleDTO = (vehicles: PathogenVehicle[]): Reservoir_Vector_Vehicle_WithReference[] =>
  transformPathogenEntities(vehicles, 'vehicle_class', 'vehicle_entity', 'vehicle_entity_latin');

export const pathogenVectorDTO = (vectors: PathogenVector[]): Reservoir_Vector_Vehicle_WithReference[] =>
  transformPathogenEntities(vectors, 'vector_class', 'vector_entity', 'vector_entity_latin');



// export const virusReservoirsDTO = (reservoirs:VirusReservoir[]):Reservoir_Vector_Vehicle_WithReference[]=>{
//   const newReservoirs = reservoirs.reduce((acc:any, item:VirusReservoir) => {
//     const existingClass = acc.find((group:VirusReservoir) => group.reservoir_class === item.reservoir_class);
  
//     if (existingClass) {
//       existingClass.reservoirEntityArr.push({
//         reservoir_entity: item.reservoir_entity,
//         reservoir_entity_latin: item.reservoir_entity_latin,
//         references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//       });
//     } else {
//       acc.push({
//         reservoir_class: item.reservoir_class,
//         isReservoirEntityLatin:(item.reservoir_entity_latin && item.reservoir_entity)?true:false, // if entity_latin and entity is not present or coming as null, using it on virusSummarySection
//         reservoirEntityArr: [{
//           reservoir_entity: item.reservoir_entity,
//           reservoir_entity_latin: item.reservoir_entity_latin,
//           references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//         }],
        
//       });
//     }
//     return acc;
//   }, []).sort((a:any, b:any) => a.reservoir_class<b.reservoir_class?-1:a.reservoir_class>b.reservoir_class?1:0);
//   return newReservoirs
// }

// export const virusVehicleDTO = (vehicles: VirusVehicle[]):Reservoir_Vector_Vehicle_WithReference[] => {
//   const newVirusVehicles = vehicles.reduce((acc: any, item: VirusVehicle) => {
//     const existingClass = acc.find((group: VirusVehicle) => group.vehicle_class === item.vehicle_class);
  
//     if (existingClass) {
//         existingClass.vehicle_entity_arr.push({
//             vehicle_entity: item.vehicle_entity,
//             vehicle_entity_latin: item.vehicle_entity_latin,
//             references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//         });
//     } else {
//         acc.push({
//             vehicle_class: item.vehicle_class,
//             isVehicleEntityLatin:(item.vehicle_entity_latin && item.vehicle_entity)?true:false, // if entity_latin and entity is not present or coming as null, using it on virusSummarySection
//             vehicle_entity_arr: [{
//                 vehicle_entity: item.vehicle_entity,
//                 vehicle_entity_latin: item.vehicle_entity_latin,
//                 references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//             }],
            
//         });
//     }
//     return acc
// }, []).sort((a: any, b: any) => a.vehicle_class < b.vehicle_class ? -1 : a.vehicle_class > b.vehicle_class ? 1 : 0);

// return newVirusVehicles;

// }

// export const virusVectorDTO = (vectors: VirusVector[]): Reservoir_Vector_Vehicle_WithReference[] => {
//   const newVirusVectors = vectors.reduce((acc: any, item: VirusVector) => {
//       const existingClass = acc.find((group: VirusVector) => group.vector_class === item.vector_class);

//       if (existingClass) {
//           existingClass.vector_entity_arr.push({
//               vector_entity: item.vector_entity,
//               vector_entity_latin: item.vector_entity_latin,
//               references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//           });
//       } else {
//           acc.push({
//               vector_class: item.vector_class,
//               isVectorEntityLatin:(item.vector_entity_latin && item.vector_entity)?true:false, // if entity_latin and entity is not present or coming as null, using it on virusSummarySection
//               vector_entity_arr: [{
//                   vector_entity: item.vector_entity,
//                   vector_entity_latin: item.vector_entity_latin,
//                   references: item.references.length && parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' ')) || [],
//               }],
//           });
//       }
//       acc.forEach((group: VirusVectorWithReference) => {
//         group.vector_entity_arr.sort((a: any, b: any) => a.vector_entity < b.vector_entity ? -1 : a.vector_entity > b.vector_entity ? 1 : 0);
//     });
//       return acc;
//   }, []).sort((a: any, b: any) => a.vector_class < b.vector_class ? -1 : a.vector_class > b.vector_class ? 1 : 0);

//   return newVirusVectors;
// };



export const mapVirusDetailsDTO = (x: VirusDetailDTO,timestamp:number): VirusDetails => ({
  code: x.id,
  name: x.virus_name,
  genome_type: parseDynamicContent(`${x.genome_type}${x.genome_type_references ? `{${x.genome_type_references}}` : ''}`),
  diseases: x.diseases.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ),
  reservoirs: x.reservoirs.length? pathogenReservoirDTO(x.reservoirs):null,
  vehicles: x.vehicles.length? pathogenVehicleDTO(x.vehicles):null,
  vectors: x.vectors.length? pathogenVectorDTO(x.vectors):null,
  drugs: x.drugs.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ).sort((a,b)=>a.drug>b.drug?1:a.drug<b.drug?-1:0),
  vaccines: x.vaccines.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ).sort((a,b)=>a.vaccine>b.vaccine?1:a.vaccine<b.vaccine?-1:0),
  synonyms: x.synonyms,
  synonymWithReference:x.synonyms_with_references?.length
  ? x.synonyms_with_references.map(item => ({
      synonym: item.synonym,
      references: item.synonym && item.references.length? parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' '))
        : null,
    }))
  : null,
  structure: parseDynamicContent(x.structure || ''),
  entry_mechanism: parseDynamicContent(x.entry_mechanism || ''),
  replication_mechanism: parseDynamicContent(x.replication_mechanism || ''),
  structure_images: x.images.filter(i => i.image_category === 'structure'),
  mechanism_images: x.images.filter(i => i.image_category === 'mechanism'),
  etymology: (x.etymology && x.etymology.length > 0) ? parseEtymologyData(x.etymology) : undefined,
  timestamp:timestamp,
});

export const mapParasiteFungusDetailsDTO = (x: ParasiteFungusDetailDTO,timestamp:number,type:'parasites'|'fungi'): ParasiteFungusDetails => ({
  code: x.id,
  name: type==='parasites'?x.parasite_name:x.fungus_name,
  diseases: x.diseases.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ),
  reservoirs: x.reservoirs.length? pathogenReservoirDTO(x.reservoirs):null,
  vehicles: x.vehicles.length? pathogenVehicleDTO(x.vehicles):null,
  vectors: x.vectors.length? pathogenVectorDTO(x.vectors):null,
  drugs: x.drugs.length?x.drugs.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ).sort((a,b)=>a.drug>b.drug?1:a.drug<b.drug?-1:0):null,
  vaccines: x.vaccines.length?x.vaccines.map(d => ({ ...d, references: d.references.length > 0 ? parseDynamicContent(d.references.map(ref => `{${ref.reference_number}}`).join(' ')) : [] }) ).sort((a,b)=>a.vaccine>b.vaccine?1:a.vaccine<b.vaccine?-1:0):null,
  synonyms: x.synonyms,
  synonymWithReference:x.synonyms_with_references?.length
  ? x.synonyms_with_references.map(item => ({
      synonym: item.synonym,
      references: item.synonym && item.references.length? parseDynamicContent(item.references.map(ref => `{${ref.reference_number}}`).join(' '))
        : null,
    }))
  : null,
  habitat:x.habitat?parseDynamicContent(x.habitat):null,
  characteristics:x.characteristics?parseDynamicContent(x.characteristics):null,
  lifecycle:x.lifecycle?parseDynamicContent(x.lifecycle):null,
  identification_notes:x.identification_notes?parseDynamicContent(x.identification_notes):null,
  images: x.images.length?x.images:undefined,
  etymology: (x.etymology && x.etymology.length > 0) ? parseEtymologyData(x.etymology) : undefined,
  timestamp:timestamp,
});

const simpleMicrobeSort = (first: SimpleMicrobe, second: SimpleMicrobe): number => {
  return first.name.toLowerCase().localeCompare(second.name.toLowerCase());
};

export const mapBacteriaDTO = (bacteria: SimpleBacteriaDTO): SimpleMicrobe[] => {
  return bacteria.data
    ? bacteria.data.map(microbe => ({
        id: microbe.bacteria_code,
        name: microbe.bacteria,
        type: 'bacteria',
      }))
    : [];
};

export const mapMycobacteriaDTO = (mycobacteria: SimpleMycobacteriaDTO): SimpleMicrobe[] => {
  return mycobacteria.data
    ? mycobacteria.data.map(mycobacteria => ({
        id: mycobacteria.mycobacteria_code,
        name: mycobacteria.mycobacteria,
        type: 'mycobacteria',
      }))
    : [];
};

export const mapYeastsDTO = (yeasts: SimpleYeastDTO): SimpleMicrobe[] => {
  return yeasts.data
    ? yeasts.data.map(yeast => ({
        id: yeast.yeast_code,
        name: yeast.yeast,
        type: 'yeasts',
      }))
    : [];
};

export const mapVirusDTO = (virus: SimpleVirusDTO): SimpleMicrobe[] => {
  return virus.data
    ? virus.data.map(d => ({
        id: d.virus_code,
        name: d.virus,
        type: 'viruses',
      }))
    : [];
};

export const mapParasiteDTO = (parasite: SimpleParasiteDTO): SimpleMicrobe[] => {
  return parasite.data
    ? parasite.data.map(d => ({
        id: d.parasite_code,
        name: d.parasite,
        type: 'parasites',
      }))
    : [];
};

export const mapFungiDTO = (fungus: SimpleFungusDTO): SimpleMicrobe[] => {
  return fungus.data
    ? fungus.data.map(d => ({
        id: d.fungus_code,
        name: d.fungus,
        type: 'fungi',
      }))
    : [];
};

export const mapAllMicrobeDTO = (
  bacteria: SimpleBacteriaDTO,
  mycobacteria: SimpleMycobacteriaDTO,
  yeasts: SimpleYeastDTO,
  virus: SimpleVirusDTO,
  parasites:SimpleParasiteDTO,
  fungi:SimpleFungusDTO
): SimpleMicrobe[] => {
  const bacteriaMicrobes: SimpleMicrobe[] = mapBacteriaDTO(bacteria);

  const mycobacteriaMicrobes: SimpleMicrobe[] = mapMycobacteriaDTO(mycobacteria);

  const yeastMicrobes: SimpleMicrobe[] = mapYeastsDTO(yeasts);

  const virusMicrobes: SimpleMicrobe[] = mapVirusDTO(virus);

  const ParasitePathogen: SimpleMicrobe[] = mapParasiteDTO(parasites);
  
  const fungiPathogen: SimpleMicrobe[] = mapFungiDTO(fungi);

  return [...bacteriaMicrobes, ...mycobacteriaMicrobes, ...yeastMicrobes, ...virusMicrobes, ...ParasitePathogen, ...fungiPathogen];
};
