import { contentClassNames, Modal } from 'Atoms/Modal';
import { H2 } from 'Atoms/text';
import { darken } from 'polished';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

import { ModalCloseButton } from '../buttons/ModalCloseButton';

interface Props {
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: ReactNode;
  contentLabel?: string;
}

export const StyledModal = styled(Modal)`
  .${contentClassNames.base} {
    margin-top: 100px;
    background: #f8f9fa;
    max-width: 600px;
    max-height: auto;
    width: 491px;
    height: 329px;
    border-radius: 10px;
    border: 1px solid #0077a0;
    ${ScrollbarMixin}
    @media (max-width: ${props => props.theme.breakpoints.s}) {
      max-width: 95%;
    }
  }
`;

export const Title = styled(H2)`
  text-align: left;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    margin: 10px 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 25px;
  }
`;

export const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 450;
  padding: 1% 4%;
  background: ${props => darken(0.03, props.theme.colors.background.primary)};
  box-shadow: 0 6px 5px 0px rgba(0, 0, 0, 0.3);
`;

export const Content = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 4%;
`;

export const StyledClosedButton = styled(ModalCloseButton)`
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    top: 15px;
    right: 10px;
  }
`;

export const ExploreGraduationModal: FC<Props> = ({
  isOpen,
  onClose,
  children,
  title,
  className,
  contentLabel,
}) => {
  return (
    <StyledModal
      className={className}
      isOpen={isOpen}
      onClose={onClose}
      contentLabel={contentLabel}
    >
      <ModalHeader>
        <StyledClosedButton onClick={onClose} aria-label="Modal close" />
        {title && (
          <Title font="Quicksand" weight="500" color="mainBold">
            {title}
          </Title>
        )}
      </ModalHeader>
      <Content>{children}</Content>
    </StyledModal>
  );
};
