import React, { FC, SetStateAction, useMemo } from 'react';
import { ContextValue, useStorage } from 'services/localStorage/localStorage.factory';

type NotificationClickedStateContextType = ContextValue<boolean>;

export const NotificationClickedStateContext = React.createContext<NotificationClickedStateContextType>(
  undefined
);

export const NotificationClickedStateProvider: FC = ({ children }) => {
  const [isNotificationClicked, setIsNotificationClicked] = useStorage<boolean>(
    window.localStorage,
    'isNotificationClicked',
    false
  );

  const contextValue: NotificationClickedStateContextType = useMemo(() => {
    const set = (value: SetStateAction<boolean>): void => {
      setIsNotificationClicked(typeof value === 'function' ? value(isNotificationClicked) : value);
    };

    return [isNotificationClicked, set];
  }, [isNotificationClicked, setIsNotificationClicked]);

  return (
    <NotificationClickedStateContext.Provider value={contextValue}>
      {children}
    </NotificationClickedStateContext.Provider>
  );
};
