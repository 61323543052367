import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { ParasiteFungusSummarySection } from 'Molecules/section/ParasiteFungusSummarySection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { Paywall } from 'Organisms/paywall/Paywall';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { Element } from 'react-scroll';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useParasiteDetailsPublic } from 'store/parasiteDetailStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'types/common';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  parasiteId: number;
};

export const ParasitePublic: FC<Props> = ({ location, parasiteId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showCharacteristics, setShowCharacteristics] = useState(true);

  const parasite = useParasiteDetailsPublic(parasiteId);

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(parasite)) {
    return <Loader />;
  }
  assertIsNotStoreError(parasite);

  const sections: RecordLink[] = [
    'Summary',
    'Physical Characteristics',
    'Habitat',
    'Lifecycle',
    'Images',
    'Identification notes',
    'Downloads and References',
  ].map(section => ({
    label: section,
    'aria-label': `${parasite.name} ${section.toLowerCase()}`,
    disabled:
      (section === 'Images' && parasite.images === undefined) ||
      (section === 'Physical Characteristics' && parasite.characteristics === null) ||
      (section === 'Habitat' && parasite.habitat === null) ||
      (section === 'Lifecycle' && parasite.lifecycle === null) ||
      (section === 'Identification notes' && parasite.identification_notes === null),
  }));
  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{parasite.name} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={parasite.name}>
          <RecordLayout>
            <RecordTitle title={parasite.name} synonymName={synonymName} />
            <RecordLinks links={sections} />

            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="virus-summary"
            >
              <ParasiteFungusSummarySection
                diseases={parasite.diseases}
                reservoirs={parasite.reservoirs}
                vehicles={parasite.vehicles}
                vectors={parasite.vectors}
                vaccines={parasite.vaccines}
                drugs={parasite.drugs}
                etymology={parasite.etymology}
              />
            </RecordSection>
            {parasite.characteristics && (
              <RecordSection
                link={sections[1]}
                show={showCharacteristics}
                onCollapse={() => setShowCharacteristics(!showCharacteristics)}
                accent="second"
                id="parasite-characteristics"
              >
                <DynamicHtml content={parasite.characteristics} />
              </RecordSection>
            )}
            <Element name="Habitat">
              <Element name="Lifecycle">
                <Element name="Structure gallery">
                  <Element name="Mechanism of infection gallery">
                    <Element name="Identification notes">
                      <Element name="Downloads and references"></Element>
                      <Paywall />
                    </Element>
                  </Element>
                </Element>
              </Element>
            </Element>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
