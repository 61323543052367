import diseasesDarkBackground from 'assets/Backgrounds/diseases_dark.jpeg';
import drugsDarkBackground from 'assets/Backgrounds/drugs_dark.jpeg';
import microbesDarkBackground from 'assets/Backgrounds/microbes_dark.jpeg';
import vaccinesDarkBackground from 'assets/Backgrounds/vaccines_dark.jpeg';
import { ReactComponent as BrokenLinkSvg } from 'assets/EmailConfirm/BrokenLinkDark.svg';
import { ReactComponent as ClockSvg } from 'assets/Expiration/ClockDark.svg';
import { ReactComponent as AgentSvg } from 'assets/Fingerprint/AgentDark.svg';
import { ReactComponent as CountrySvg } from 'assets/Fingerprint/CountryDark.svg';
import { ReactComponent as ReservoirSvg } from 'assets/Fingerprint/ReservoirDark.svg';
import { ReactComponent as VectorSvg } from 'assets/Fingerprint/VectorDark.svg';
import { ReactComponent as VehicleSvg } from 'assets/Fingerprint/VehicleDark.svg';
import FullDarkLogoPng from 'assets/full-logo-dark.png';
import { ReactComponent as AccountSvg } from 'assets/Login/AccountDark.svg';
import { ReactComponent as InstitutionSvg } from 'assets/Login/InstitutionDark.svg';
import { ReactComponent as LockSvg } from 'assets/Login/LockDark.svg';
import { ReactComponent as PasswordSvg } from 'assets/Login/PasswordDark.svg';
import { ReactComponent as ReadingSvg } from 'assets/Login/ReadingDark.svg';
import { ReactComponent as UserSvg } from 'assets/Login/UserDark.svg';
import LogoPng from 'assets/logo.png';
import MailIcon from 'assets/Start/MailinboxIcon.svg';
import { ReactComponent as eBookSVG } from 'assets/UI/eBookDark.svg';
import { ReactComponent as Help } from 'assets/UI/HelpDark.svg';
import { ReactComponent as LightbulbSvg } from 'assets/UI/Lightbulb_darkmode.svg';
import { ReactComponent as PasswordHide } from 'assets/UI/PasswordHideDark.svg';
import { ReactComponent as PasswordShow } from 'assets/UI/PasswordShowDark.svg';
import PlusWhite from 'assets/UI/PlusWhite.svg';
import CrossSVG from 'assets/UI/Remove.svg';
import { lighten, mix, rgba } from 'polished';
import { DefaultTheme } from 'styled-components/macro';
import { updatesDarkThemeIcons } from 'Updates/services/updatesDarkThemeIcons';

import { commonTheme } from './commonTheme';

const mainBackground = '#202124';
const backgroundDim = `linear-gradient(${rgba('#000', 0.5)}, ${rgba('#000', 0.5)})`;

export const darkTheme: DefaultTheme = {
  ...commonTheme,
  colors: {
    background: {
      primary: mainBackground,
      navbar: rgba('#FFFFFF', 0.09),
      secondaryNavbar: '#04384A',
      secondaryNavbarActiveLink: rgba('#FFFFFF', 0.1),
      footer: rgba('#FFFFFF', 0.12),
      searchBar: '#2A2A2A',
      modalCardHeader: '#343538',
      modalCardBody: '#292929',
      recordTitle: '#022A38',
      lightPopup: '#009FD5',
      darkPopup: '#005B7A',
      differentialDiagnosisPreview: rgba('#FFFFFF', 0.1),
    },
    button: {
      default: {
        backgroundColor: 'transparent',
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
      },
      hover: {
        backgroundColor: 'transparent',
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
      active: {
        backgroundColor: 'transparent',
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
      disabled: {
        backgroundColor: 'transparent',
        borderColor: rgba(170, 170, 170, 0.8),
        textColor: rgba(170, 170, 170, 0.8),
      },
      focus: {
        backgroundColor: 'transparent',
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
        outline: '#F4C74F',
      },
    },
    filledButton: {
      default: {
        default: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          boxShadow: '0px 1px 4px 0px rgba(2,42,56,0.4)',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25)',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
        },
        focus: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          outline: '#F4C74F',
        },
      },
      light: {
        default: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          boxShadow: '0px 1px 4px 0px rgba(2,42,56,0.4)',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25)',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25, 0.25), 0px 4px 4px rgba(2,42,56, 0.25, 0.25), 0px 4px 4px rgba(2,42,56, 0.7)',
        },
        focus: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          outline: '#F4C74F',
        },
      },
      dark: {
        default: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          boxShadow: '0px 1px 4px 0px rgba(2,42,56,0.4)',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
          boxShadow:
            '0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25), 0px 4px 4px rgba(2,42,56, 0.25)',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
        },
        focus: {
          backgroundColor: '#004F6B',
          borderColor: '#004F6B',
          textColor: rgba('#FFFFFF', 0.8),
          outline: '#F4C74F',
        },
      },
      special: {
        default: {
          backgroundColor: '#3FBAFD',
          borderColor: '#3FBAFD',
          textColor: '#000000',
        },
        hover: {
          backgroundColor: '#202124',
          borderColor: '#00ACEA',
          textColor: '#7FCFEA',
        },
        active: {
          backgroundColor: '#131617',
          borderColor: '#00ACEA',
          textColor: '#00ACEA',
        },
        focus: {
          backgroundColor: '#202124',
          borderColor: '#202124',
          textColor: '#7FCFEA',
          outline: '#F4C74F',
        },
      },
    },
    reportButton: {
      default: {
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: '#7FCFEA',
        borderColor: rgba('#FFFFFF', 0.6),
      },
      hover: {
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: '#7FCFEA',
        borderColor: '#7FCFEA',
      },
      icon: '#F4C74F',
    },
    circleButton: {
      default: {
        borderColor: 'transparent',
        backgroundColor: rgba('#FFFFFF', 0.14),
        textColor: '#7FCFEA',
      },
      hover: {
        borderColor: '#00ACEA',
        backgroundColor: '#202124',
        textColor: '#7FCFEA',
      },
    },
    nextButton: {
      default: {
        borderColor: '#AAAAAA',
        backgroundColor: '#292929',
        textColor: '#AAAAAA',
      },
      hover: {
        borderColor: '#51CF66',
        backgroundColor: '#51CF66',
        textColor: '#FFFFFF',
      },
      active: {
        borderColor: '#51CF66',
        backgroundColor: '#292929',
        textColor: rgba('#FFFFFF', 0.8),
      },
      focus: {
        borderColor: '#AAAAAA',
        backgroundColor: '#292929',
        textColor: '#AAAAAA',
      },
    },
    yesNoButton: {
      default: {
        backgroundColor: '#303133',
        borderColor: '#AAAAAA',
        textColor: rgba('#FFFFFF', 0.8),
      },
      hover: {
        backgroundColor: '#202124',
        borderColor: '#00ACEA',
        textColor: '#7FCFEA',
      },
      active: {
        backgroundColor: '#7FCFEA',
        borderColor: '#009FD5',
        textColor: '#000000',
      },
      focus: {
        backgroundColor: '#303133',
        borderColor: '#AAAAAA',
        textColor: rgba('#FFFFFF', 0.8),
      },
    },
    select: {
      default: {
        background: rgba('#FFFFFF', 0.1),
        border: rgba('#FFFFFF', 0.8),
        text: rgba('#FFFFFF', 0.8),
        placeholder: rgba('#FFFFFF', 0.8),
        menuBackground: rgba('#000000', 0.9),
        multiOption: {
          text: '#FFFFFF',
          background: '#0077A0',
          closeButtonBackground: rgba(127, 207, 234, 0.5),
          closeButton: '#000000',
        },
      },
      active: {
        border: '#7FCFEA',
        text: rgba('#FFFFFF', 0.87),
      },
      hover: {
        option: rgba(127, 207, 234, 0.2),
      },
      focus: {
        border: '#F4C74F',
      },
    },
    modal: {
      base: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
      afterOpen: {
        overlayBackground: rgba(0, 0, 0, 0.4),
      },
      beforeClose: {
        overlayBackground: rgba(0, 0, 0, 0),
      },
    },
    scrollbar: {
      track: '#999',
      thumb: rgba('#7FCFEA', 0.8),
    },
    lightScrollbar: {
      track: 'transparent',
      thumb: '#AAAAAA',
    },
    breadcrumb: {
      containerBorderColor: 'none',
      menuBackgroundColor: rgba('#000000', 0.8),
      default: {
        borderColor: '#0077A0',
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: rgba('#FFFFFF', 0.8),
        iconColor: rgba('#FFFFFF', 0.8),
        selectOptionColor: rgba('#FFFFFF', 0.8),
        selectOptionBackgroundColor: rgba(127, 207, 234, 0.2),
      },
      hover: {
        borderColor: '#7FCFEA',
        backgroundColor: rgba('#FFFFFF', 0.07),
        textColor: '#7FCFEA',
        iconColor: '#7FCFEA',
        selectOptionColor: rgba('#FFFFFF', 0.8),
        selectOptionBackgroundColor: rgba(127, 207, 234, 0.2),
      },
    },
    mobileSecondaryNavbar: {
      borderColor: rgba(255, 255, 255, 0.1),
    },
    azListSection: {
      borderColor: '#055B7A',
    },
    tab: {
      default: rgba('#FFFFFF', 0.8),
      hover: '#0077A0',
      active: '#7FCFEA',
      border: '#AAAAAA',
      borderActive: '#00ACEA',
      focus: '#F4C74F',
    },
    mobileNavigationRow: {
      default: {
        textColor: '#7FCFEA',
        borderColor: rgba('#FFFFFF', 0.6),
        backgroundColor: 'transparent',
      },
      active: {
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
        backgroundColor: 'transparent',
      },
    },
    mobileNavbarLink: {
      default: {
        backgroundColor: 'transparent',
        borderColor: 'none',
        textColor: rgba(255, 255, 255, 0.8),
      },
      active: {
        backgroundColor: '#055B7A',
        borderColor: '#009FD5',
        textColor: '#FFFFFF',
      },
    },
    accountMenu: {
      default: {
        backgroundColor: mix(0.16, '#FFFFFF', mainBackground),
        borderColor: rgba('#FFFFFF', 0.8),
        textColor: rgba('#FFFFFF', 0.8),
      },
      hover: {
        backgroundColor: mix(0.16, '#FFFFFF', mainBackground),
        borderColor: '#7FCFEA',
        textColor: '#7FCFEA',
      },
    },
    startItem: {
      border: '#595959',
      background: rgba(255, 255, 255, 0.07),
      disabledBackground: rgba(0, 0, 0, 0.6),
      disabledIcon: '#4F4F4F',
    },
    checkbox: {
      defaultBorder: rgba('#FFFFFF', 0.8),
      hoverBorder: '#7FCFEA',
    },
    radio: {
      border: rgba('#FFFFFF', 0.8),
      borderChecked: '#0077A0',
      background: 'transparent',
      backgroundChecked: rgba(128, 207, 234, 0.1),
      checkmark: '#0077A0',
      checkmarkBorder: 'transparent',
    },
    datepicker: {
      border: '#AAAAAA',
      focus: '#0077A0',
      placeholder: rgba('#FFFFFF', 0.6),
      text: rgba('#FFFFFF', 0.8),
      icon: rgba('#FFFFFF', 0.8),
      calendar: {
        background: '#4a4a4a',
        arrowButtonBackground: '#4f4f4f',
        hoverBackground: '#636363',
        weeksBackground: '#636363',
        border: '#FFFFFF',
        shortcuts: {
          background: '#4f4f4f',
          text: rgba('#FFFFFF', 0.8),
        },
      },
    },
    search: {
      default: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        boxShadowColor: 'transparent',
      },
      hover: {
        backgroundColor: '#343538',
        borderColor: 'transparent',
        boxShadowColor: rgba(0, 0, 0, 0.2),
      },
    },
    text: {
      main: rgba('#FFFFFF', 0.8),
      mainBold: rgba('#FFFFFF', 0.87),
      timeStamp:rgba('#FFFFFF', 0.87),
      giftText:rgba('#007BD5',0.8),
      secondary: rgba('#FFFFFF', 0.8),
      inactive: rgba('#FFFFFF', 0.8),
      navbar: rgba('#FFFFFF', 0.8),
      navbarActive: '#7FCFEA',
      secondaryNavbar: rgba('#FFFFFF', 0.87),
      secondaryNavbarActive: '#FFFFFF',
      darkLink: rgba('#7FCFEA', 0.8),
      lightLink: '#7FCFEA',
      input: rgba('#FFFFFF', 0.8),
      selectInput: rgba('#FFFFFF', 0.87),
      errorTitle: rgba('#FFFFFF', 0.87),
      modalCardTitle: rgba('#FFFFFF', 0.87),
      exploreLandingTitle: rgba('#7FCFEA', 0.87),
      disabledNavLink: rgba(170, 170, 170, 0.8),
      error: rgba('#F75555', 0.8),
    },
    toast: {
      success: {
        text: '#000000',
        background: '#0ACD10',
        outline: '#F4C74F',
      },
      error: {
        background: '#A82315',
        text: '#FFFFFF',
        outline: '#F4C74F',
      },
      info: {
        background: '#0054AD',
        text: '#FFFFFF',
        outline: '#F4C74F',
      },
      warning: {
        background: '#F4C74F',
        text: '#000000',
        outline: '#F4C74F',
      },
    },
    switch: {
      on: '#00BCD4',
      off: '#AAAAAA',
    },
    recordBorder: {
      first: rgba('#7FCFEA', 0.8),
      second: rgba('#009FD5', 0.8),
      third: rgba('#128DB8', 0.8),
      fourth: rgba('#0077A0', 0.8),
      fifth: rgba('#004F6B', 0.8),
    },
    recordSection: {
      shadow: rgba('#000000', 0.2),
      border: rgba(128, 207, 234, 0.2),
    },
    imageGallery: {
      default: '#7FCFEA',
      hover: '#0077A0',
      indexBackground: rgba(0, 0, 0, 0.4),
      progressBar: '#0077A0',
      progressBarActive: '#7FCFEA',
    },
    table: {
      border: '#055B7A',
      heading: {
        backgroundColor: '#055B7A',
        text: rgba(255, 255, 255, 0.87),
      },
      row: {
        backgroundColor: mainBackground,
        backgroundHoverColor: '#303030',
        text: rgba(255, 255, 255, 0.8),
      },
      scrollShadow: rgba(255, 255, 255, 0.2),
      sortButton: rgba(255, 255, 255, 0.87),
      compareTable: {
        border: '#AAAAAA',
        sortButton: '#AAAAAA',
        headingText: rgba('#FFFFFF', 0.8),
        shadowRight:
          'linear-gradient(to left, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
        shadowLeft:
          'linear-gradient(to right, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%)',
        shadowArrow: '#DEE2E6',
        icon: '#DEE2E6',
        probabilityKeyBackground: rgba(55, 56, 59, 0.2),
        probabilityKeyBorder: rgba('#7FCFEA', 0.2),
        sectionBorder: rgba(170, 170, 170, 0.8),
      },
    },
    separator: {
      light: '#AAAAAA',
      dark: '#595959',
      colored: '#009fd5',
    },
    listItem: {
      border: '#DEE2E6',
    },
    scrollToTopButton: {
      background: '#7FCFEA',
      border: '#000000',
    },
    comparisonColors: {
      Missing: '#969595',
      Never: '#AAAAAA',
      Rarely: '#F75555',
      Often: '#ffb400',
      Usually: '#0BCB2B',
      Always: '#47BAE1',
    },
    stepIndicator: {
      default: '#AAAAAA',
      active: '#7FCFEA',
      completed: '#51CF66',
      completedNegative: '#C70E0E',
    },
    ageSlider: {
      sliderSelected: '#51CF66',
      slider: '#AAAAAA',
      buttonIcon: '#1971C2',
    },
    progressBar: {
      background: '#4F4F4F',
      progress: '#51CF66',
    },
    stepTreeProgressBar: {
      background: '#4F4F4F',
      fillColors: [rgba('#F75555', 0.8), rgba('#FA9200', 0.8), rgba('#0BCB2B', 0.8)],
    },
    chip: {
      background: rgba('#007096CC',0.8),
      text: rgba('#FFFFFF',0.8),
    },
    tooltip: {
      background: '#cfcfcf',
      text: '#000000',
    },
    method: {
      default: {
        background: '#303133',
        border: rgba('#FFFFFF', 0.8),
      },
      hover: {
        background: '#202124',
        border: '#00ACEA',
      },
      labels: {
        classic: {
          background: '#FFB300',
          border: '#FFE100',
          text: '#000000',
        },
        interactive: {
          background: '#14BCF9',
          border: '#7FCFEA',
          text: '#000000',
        },
      },
    },
    graphsTable: {
      head: {
        desktop: {
          background: 'transparent',
          text: rgba('#FFFFFF', 0.8),
        },
        mobile: {
          background: '#0077A0',
          text: '#FFFFFF',
        },
      },
      row: {
        highlight: '#313236',
        hover: rgba('#7FCFEA', 0.15),
        active: rgba('#7FCFEA', 0.35),
        activeBorder: '#AAAAAA',
      },
    },
    buttonHoverBackground: '#2a2b2e',
    symptomGroupButton: {
      background: rgba(255, 255, 255, 0.05),
      border: '#7FCFEA',
      hoverBackground: '#202124',
    },
    point: {
      default: {
        background: '#202124',
        border: '#009FD5',
      },
      hover: {
        background: '#A31519',
        border: '#009FD5',
      },
      active: {
        background: '#A31519',
        border: '#000000',
      },
    },
    labTree: {
      treeElementButton: {
        default: {
          background: '#303133',
          border: '#DEE2E6',
          text: rgba('#FFFFFF', 0.8),
          containerBackground: '#27282c',
          icon: '#D6D6D6',
        },
        active: {
          background: '#022A38',
          border: '#0077A0',
          text: '#7FCFEA',
          icon: '#B7E9FA',
        },
        hover: {
          background: '#202124',
          border: '#00ACEA',
          text: '#00ACEA',
        },
      },
      buttonBorder: '#DEE2E6',
      border: '#555555',
      background: lighten(0.03, mainBackground),
      overlay: {
        background: '#F4C74F',
      },
      previewList: {
        closedActiveBackground: '#0077A0',
      },
    },
    treeIndicator: {
      active: {
        text: '#7FCFEA',
        tick: '#7FCFEA',
      },
      completed: {
        text: rgba(255, 255, 255, 0.8),
        tick: '#29BF42',
      },
      inactive: {
        text: '#AAAAAA',
        tick: '#AAAAAA',
      },
    },
    finding: {
      border: {
        default: rgba('#FFFFFF', 0.8),
        active: '#0077A0',
        inactive: '#666666',
      },
      icon: {
        default: rgba(255, 255, 255, 0.8),
        active: '#7FCFEA',
        inactive: '#666666',
      },
      background: {
        default: 'transparent',
        active: '#022A38',
        inactive: '#202124',
      },
      text: {
        default: rgba('#FFFFFF', 0.8),
        active: '#7FCFEA',
        inactive: '#AAAAAA',
      },
    },
    settings: {
      sectionBorder: rgba('#7FCFEA', 0.8),
      sectionSeparator: '#7FCFEA',
      inputBorder: '#AAAAAA',
      inputText: rgba('#FFFFFF', 0.8),
    },
    accent: {
      1: rgba('#F75555', 0.8),
      2: rgba('#FA9200', 0.8),
      3: rgba('#0BCB2B', 0.8),
      4: '#47BAE1',
      5: '#AAAAAA',
      6: rgba('#F75555', 0.8),
      7: '#7FCFEA',
    },
    loader: rgba('#009FD5', 0.8),
    lazyLoadPlaceholder: {
      background: '#333333',
      shine: `linear-gradient(to right, rgba(100, 100, 100, 0) 0%, rgba(100, 100, 100, 0.4) 50%, rgba(50, 50, 50, 0) 100%);`,
    },
    outbreaksMap: {
      noData: '#333333',
      endemicColors: {
        threeLevelEndemic: ['#5b5b5d', '#0074b3', '#1a1970'],
        fiveLevelEndemic: ['#5b5b5d', '#42e0ff', '#0074b3', '#003ab8', '#1a1970'],
        sixLevelEndemic: ['#5b5b5d', '#42e0ff', '#0f8fa9', '#0074b3', '#003ab8', '#1a1970'],
      },
      accessibilityEndemicColors: {
        threeLevelEndemic: ['#edf8b1', '#7fcdbb', '#2c7fb8'],
        fiveLevelEndemic: ['#ffffcc', '#c7e9b4', '#41b6c4', '#2c7fb8', '#253494'],
        sixLevelEndemic: ['#ffffcc', '#c7e9b4', '#7fcdbb', '#41b6c4', '#2c7fb8', '#253494'],
      },
      border: '#888',
      label: '#CCCCCC',
    },
    expirationButtons: {
      order: '#004F6B',
      quote: '#055B7A',
      talk: '#0077A0',
      text: rgba('#FFFFFF', 0.8),
    },
    sectionHead: {
      background: '#004F6B',
      border: '#004F6B',
    },
    updatesActiveSection: {
      background: '#004f6b',
      border: '#0ABFFC',
    },
    paywall: {
      overlay: `linear-gradient(rgba(32, 33, 36, 0), rgba(32, 33, 36, 1))`,
    },
    referencesPage: {
      message: { background: '#022A38', border: '#00ACEA' },
    },
    videoButton: {
      background: '#4F4F4F',
      border: '#222222',
      color: '#FFFFFF',
    },
    trial: {
      socialProof: {
        border: '#404043',
        background: '#404043',
      },
      success: '#51CF66',
    },
    whyNotItem: {
      button: '#FFB300',
      border: '#FFB300',
      text: '#000000',
    },
    focus: '#F4C74F',
    helpTooltip: '#0056b3',
    countriesTitle: '#464646',
    comparisonCount: '#00acea',
  },
  images: {
    logo: FullDarkLogoPng,
    mobileLogo: LogoPng,
    compareButtonPlus: PlusWhite,
    mailInboxButton: MailIcon,
    removeButtonCross: CrossSVG,
    compareLightbulb: LightbulbSvg,
    user: UserSvg,
    password: PasswordSvg,
    lock: LockSvg,
    institution: InstitutionSvg,
    reading: ReadingSvg,
    account: AccountSvg,
    eBooks: eBookSVG,
    fingerprint: {
      agent: AgentSvg,
      vector: VectorSvg,
      vehicle: VehicleSvg,
      reservoir: ReservoirSvg,
      country: CountrySvg,
    },
    updates: updatesDarkThemeIcons,
    brokenLink: BrokenLinkSvg,
    clock: ClockSvg,
    help: Help,
    passwordHide: PasswordHide,
    passwordShow: PasswordShow,
  },
  backgroundImages: {
    diseases: {
      desktop: `${backgroundDim}, url(${diseasesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
      mobile: `${backgroundDim}, url(${diseasesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
    },
    drugs: {
      desktop: `${backgroundDim}, url(${drugsDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
      mobile: `${backgroundDim}, url(${drugsDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
    },
    microbes: {
      desktop: `url(${microbesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
      mobile: `url(${microbesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
    },
    vaccines: {
      desktop: `${backgroundDim}, url(${vaccinesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
      mobile: `${backgroundDim}, url(${vaccinesDarkBackground}) ${mainBackground} center center / cover no-repeat fixed`,
    }
  },
  mapBackground: `linear-gradient(${rgba('#000', 0.8)}, ${rgba('#000', 0.8)})`
};
