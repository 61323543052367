import { NavbarLink } from 'Atoms/links/NavLinks';
import { AccountMenu } from 'Organisms/AccountMenu';
import React, { FC, KeyboardEvent, MutableRefObject, useRef, useState } from 'react';
import { LoginModalContext } from 'services/loginModal.service';
import { useContextAssert } from 'services/useContextAssert.hook';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';
import { AccountMenuOption } from 'types/accountMenuOption';
import {
  diagnoseLinks,
  exploreLinks,
  labLinks,
  publicVisualizeLinks,
  visualizeLinks,
} from 'types/navbar';

import {
  AZButton,
  CompareButton,
  CompareButtonPublic,
  DisabledNavbarLinkStyled,
  SearchButton,
  SearchButtonPublic,
} from './NavbarButtons';
import { SecondaryNavbar } from './SecondaryNavbar';

const Container = styled.div`
  flex-grow: 1;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;

  height: 100%;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: none;
  }
`;

const NavLink = styled(NavbarLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DisabledLink = styled(DisabledNavbarLinkStyled)`
  &::after {
    margin-top: 2px;
    content: '';
    display: block;
    height: 3px;
  }
`;

const LinkContainer = styled.div`
  height: 100%;
  margin-right: 50px;

  display: flex;
  align-items: center;
`;

type References = MutableRefObject<(HTMLElement | null)[]>;

const onKeyDownLink = (
  e: KeyboardEvent<HTMLElement>,
  index: number,
  refs: References,
  secondaryRefs?: References
): void => {
  if (e.key === 'ArrowDown' && secondaryRefs) {
    e.preventDefault();

    if (secondaryRefs.current[0]) {
      secondaryRefs.current[0].focus();
    }
  } else if (e.key === 'ArrowRight') {
    const right = refs.current[index + 1];

    if (right) {
      right.focus();
    }
  } else if (e.key === 'ArrowLeft') {
    const left = refs.current[index - 1];

    if (left) {
      left.focus();
    }
  }
};

const onKeyDownSecondaryLink = (
  e: KeyboardEvent,
  index: number,
  refs: References,
  parentElement?: HTMLElement | null
): void => {
  if ((e.key === 'Escape' || e.key === 'ArrowUp') && parentElement) {
    parentElement.focus();
  } else if (e.key === 'ArrowLeft') {
    e.preventDefault();
    e.stopPropagation();

    const prevRef = refs.current[index - 1];
    if (prevRef) {
      prevRef.focus();
    }
  } else if (e.key === 'ArrowRight') {
    e.preventDefault();
    e.stopPropagation();

    const nextRef = refs.current[index + 1];
    if (nextRef) {
      nextRef.focus();
    }
  }
};

interface PublicProps {
  refs: References;
}

export const NavbarLinksLeftSidePublic: FC<PublicProps> = ({ refs }) => {
  const [labProps, labTooltip, setLabElement] = useTooltip('Sign in to access this module');
  const [diagnoseProps, diagnoseTooltip, setDiagnoseElement] = useTooltip(
    'Sign in to access this module'
  );

  const [isExploreOpen, setIsExploreOpen] = useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const { setOpen } = useContextAssert(LoginModalContext);

  const exploreRefs = useRef<(HTMLElement | null)[]>([]);
  const visualizeRefs = useRef<(HTMLElement | null)[]>([]);

  return (
    <Container>
      <LinkContainer
        onFocus={() => setIsExploreOpen(true)}
        onBlur={() => setIsExploreOpen(false)}
        onMouseOver={() => setIsExploreOpen(true)}
        onMouseOut={() => setIsExploreOpen(false)}
      >
        <NavLink
          to="/explore"
          isActive={() => isExploreOpen}
          ref={ref => (refs.current[0] = ref)}
          onKeyDown={e => onKeyDownLink(e, 0, refs, exploreRefs)}
        >
          Explore
        </NavLink>
        <SecondaryNavbar
          isOpen={isExploreOpen}
          refs={exploreRefs}
          links={exploreLinks}
          title="Explore"
          arrowXPosition={50}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, exploreRefs, refs.current[0])}
        />
      </LinkContainer>
      <DisabledLink
        {...labProps.aria}
        {...labProps.events}
        onClick={() => setOpen(true, '/lab')}
        ref={setLabElement}
      >
        Lab
        {labTooltip}
      </DisabledLink>
      <DisabledLink
        {...diagnoseProps.aria}
        {...diagnoseProps.events}
        onClick={() => setOpen(true, '/diagnose')}
        ref={setDiagnoseElement}
      >
        Diagnose
        {diagnoseTooltip}
      </DisabledLink>
      <LinkContainer
        onFocus={() => setIsVisualizeOpen(true)}
        onBlur={() => setIsVisualizeOpen(false)}
        onMouseOver={() => setIsVisualizeOpen(true)}
        onMouseOut={() => setIsVisualizeOpen(false)}
      >
        <NavLink
          to="/visualize"
          isActive={() => isVisualizeOpen}
          ref={ref => (refs.current[1] = ref)}
          onKeyDown={e => onKeyDownLink(e, 1, refs, visualizeRefs)}
        >
          Visualize
        </NavLink>
        <SecondaryNavbar
          isOpen={isVisualizeOpen}
          refs={visualizeRefs}
          links={publicVisualizeLinks}
          title="Visualize"
          arrowXPosition={365}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, visualizeRefs, refs.current[1])}
        />
      </LinkContainer>
    </Container>
  );
};

interface NavbarLinksLeftSideProps {
  refs: MutableRefObject<(HTMLElement | null)[]>;
}

export const NavbarLinksLeftSide: FC<NavbarLinksLeftSideProps> = ({ refs }) => {
  const [isExploreOpen, setIsExploreOpen] = useState(false);
  const [isLabOpen, setIsLabOpen] = useState(false);
  const [isDiagnoseOpen, setIsDiagnoseOpen] = useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);

  const exploreRefs = useRef<(HTMLElement | null)[]>([]);
  const labRefs = useRef<(HTMLElement | null)[]>([]);
  const diagnoseRefs = useRef<(HTMLElement | null)[]>([]);
  const visualizeRefs = useRef<(HTMLElement | null)[]>([]);

  return (
    <Container>
      <LinkContainer
        role="presentation"
        onFocus={() => setIsExploreOpen(true)}
        onBlur={() => setIsExploreOpen(false)}
        onMouseOver={() => setIsExploreOpen(true)}
        onMouseOut={() => setIsExploreOpen(false)}
      >
        <NavLink
          to="/explore"
          isActive={match => isExploreOpen || !!match}
          ref={ref => (refs.current[0] = ref)}
          onKeyDown={e => onKeyDownLink(e, 0, refs, exploreRefs)}
          aria-expanded={isExploreOpen}
        >
          Explore
        </NavLink>
        <SecondaryNavbar
          isOpen={isExploreOpen}
          refs={exploreRefs}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, exploreRefs, refs.current[0])}
          links={exploreLinks}
          title="Explore"
          arrowXPosition={50}
        />
      </LinkContainer>
      <LinkContainer
        role="presentation"
        onFocus={() => setIsLabOpen(true)}
        onBlur={() => setIsLabOpen(false)}
        onMouseOver={() => setIsLabOpen(true)}
        onMouseOut={() => setIsLabOpen(false)}
      >
        <NavLink
          to="/lab"
          isActive={match => isLabOpen || !!match}
          ref={ref => (refs.current[1] = ref)}
          onKeyDown={e => onKeyDownLink(e, 1, refs, labRefs)}
          aria-expanded={isLabOpen}
        >
          Lab
        </NavLink>
        <SecondaryNavbar
          isOpen={isLabOpen}
          refs={labRefs}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, labRefs, refs.current[1])}
          links={labLinks}
          title="Lab"
          arrowXPosition={142}
        />
      </LinkContainer>
      <LinkContainer
        role="presentation"
        onFocus={() => setIsDiagnoseOpen(true)}
        onBlur={() => setIsDiagnoseOpen(false)}
        onMouseOver={() => setIsDiagnoseOpen(true)}
        onMouseOut={() => setIsDiagnoseOpen(false)}
      >
        <NavLink
          to="/diagnose"
          isActive={match => isDiagnoseOpen || !!match}
          ref={ref => (refs.current[2] = ref)}
          onKeyDown={e => onKeyDownLink(e, 2, refs, diagnoseRefs)}
          aria-expanded={isDiagnoseOpen}
        >
          Diagnose
        </NavLink>
        <SecondaryNavbar
          isOpen={isDiagnoseOpen}
          refs={diagnoseRefs}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, diagnoseRefs, refs.current[2])}
          links={diagnoseLinks}
          title="Diagnose"
          arrowXPosition={240}
        />
      </LinkContainer>
      <LinkContainer
        role="presentation"
        onFocus={() => setIsVisualizeOpen(true)}
        onBlur={() => setIsVisualizeOpen(false)}
        onMouseOver={() => setIsVisualizeOpen(true)}
        onMouseOut={() => setIsVisualizeOpen(false)}
      >
        <NavLink
          to="/visualize"
          isActive={match => isVisualizeOpen || !!match}
          ref={ref => (refs.current[3] = ref)}
          onKeyDown={e => onKeyDownLink(e, 3, refs)}
          aria-expanded={isVisualizeOpen}
        >
          Visualize
        </NavLink>
        <SecondaryNavbar
          isOpen={isVisualizeOpen}
          refs={visualizeRefs}
          onKeyDown={(e, i) => onKeyDownSecondaryLink(e, i, visualizeRefs, refs.current[3])}
          links={visualizeLinks}
          title="Visualize"
          arrowXPosition={365}
        />
      </LinkContainer>
    </Container>
  );
};

const RightSideContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: baseline;
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: calc(50% - 70px);
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    max-width: unset;
    width: 100%;
  }
`;

const Spacer = styled.div`
  display: none;
  flex-grow: 1;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    display: block;
  }
`;

interface Props {
  accountMenuOptions: AccountMenuOption[];
  refs: MutableRefObject<(HTMLElement | null)[]>;
  onKeyDown: (e: KeyboardEvent, index: number) => void;
}

export const RightLinksPublic: FC<Props> = ({ accountMenuOptions, refs, onKeyDown }) => (
  <RightSideContainer>
    <Spacer />
    <SearchButtonPublic />
    <CompareButtonPublic />
    <AZButton ref={ref => (refs.current[1] = ref)} onKeyDown={e => onKeyDown(e, 1)} />
    <AccountMenu
      options={accountMenuOptions}
      ref={ref => (refs.current[2] = ref)}
      onKeyDown={e => onKeyDown(e, 2)}
    />
  </RightSideContainer>
);

export const RightLinks: FC<Props> = ({ accountMenuOptions, refs, onKeyDown }) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const searchRefs = useRef<(HTMLElement | null)[]>([]);
  const [isCompareOpen, setIsCompareOpen] = useState(false);
  const compareRefs = useRef<(HTMLElement | null)[]>([]);
  const [isAZOpen, setIsAZOpen] = useState(false);
  const azRefs = useRef<(HTMLElement | null)[]>([]);

  return (
    <RightSideContainer>
      <Spacer />
      {/* <SearchButton ref={ref => (refs.current[4] = ref)} onKeyDown={e => onKeyDown(e, 4)} /> */}
      <LinkContainer
        role="presentation"
        onFocus={() => setIsSearchOpen(true)}
        onBlur={() => setIsSearchOpen(false)}
        onMouseOver={() => setIsSearchOpen(true)}
        onMouseOut={() => setIsSearchOpen(false)}
      >
        <NavLink
          to="/search"
          isActive={match => isSearchOpen || !!match}
          ref={ref => (refs.current[0] = ref)}
          onKeyDown={e => onKeyDownLink(e, 4, refs, searchRefs)}
          aria-expanded={isSearchOpen}
        >
          Search
        </NavLink>
      </LinkContainer>

      {/* <CompareButton ref={ref => (refs.current[5] = ref)} onKeyDown={e => onKeyDown(e, 5)} /> */}
      <LinkContainer
        role="presentation"
        onFocus={() => setIsCompareOpen(true)}
        onBlur={() => setIsCompareOpen(false)}
        onMouseOver={() => setIsCompareOpen(true)}
        onMouseOut={() => setIsCompareOpen(false)}
      >
        <NavLink
          to="/compare"
          isActive={match => isCompareOpen || !!match}
          ref={ref => (refs.current[1] = ref)}
          onKeyDown={e => onKeyDownLink(e, 5, refs, compareRefs)}
          aria-expanded={isCompareOpen}
        >
          Compare
        </NavLink>
      </LinkContainer>

      {/* <AZButton ref={ref => (refs.current[6] = ref)} onKeyDown={e => onKeyDown(e, 6)} /> */}
      <LinkContainer
        role="presentation"
        onFocus={() => setIsAZOpen(true)}
        onBlur={() => setIsAZOpen(false)}
        onMouseOver={() => setIsAZOpen(true)}
        onMouseOut={() => setIsAZOpen(false)}
      >
        <NavLink
          to="/az"
          isActive={match => isAZOpen || !!match}
          ref={ref => (refs.current[2] = ref)}
          onKeyDown={e => onKeyDownLink(e, 6, refs, azRefs)}
          aria-expanded={isAZOpen}
        >
          A-Z
        </NavLink>
      </LinkContainer>

      <AccountMenu
        options={accountMenuOptions}
        ref={ref => (refs.current[7] = ref)}
        onKeyDown={e => onKeyDown(e, 7)}
      />
    </RightSideContainer>
  );
};
