import React, { FC, SetStateAction, useMemo } from 'react';
import { ContextValue, useStorage } from 'services/localStorage/localStorage.factory';

type PromotionStateContextType = ContextValue<boolean>;

export const PromotionStateContext = React.createContext<PromotionStateContextType>(undefined);

export const PromotionStateProvider: FC = ({ children }) => {
  const [isPromotion, setIsPromotion] = useStorage<boolean>(
    window.localStorage,
    'isPromotion',
    false
  );

  const contextValue: PromotionStateContextType = useMemo(() => {
    const set = (value: SetStateAction<boolean>): void => {
      setIsPromotion(typeof value === 'function' ? value(isPromotion) : value);
    };

    return [isPromotion, set];
  }, [setIsPromotion, isPromotion]);

  return (
    <PromotionStateContext.Provider value={contextValue}>{children}</PromotionStateContext.Provider>
  );
};
