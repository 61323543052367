import { Loader } from 'Atoms/Loader';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { ImagesSection } from 'Molecules/ImagesSection';
import { RecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { MicrobePhenotypesSection } from 'Molecules/section/MicrobePhenotypesSection';
import { MicrobeSummarySection } from 'Molecules/section/MicrobeSummarySection';
import { getStringFromDynamicHtml } from 'Organisms/dynamicContent/DynamicHtmlToString';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import { SmartCompareButton } from 'Organisms/SmartCompareButton';
import React, { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router-dom';
import { getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { useMicrobeDetails } from 'store/microbeDetailsStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { pathogenCategory } from 'types/common';
import { MicrobeType } from 'types/microbeDetails';
import { isRecordLocationState } from 'types/record';

type Props = RouteComponentProps & {
  type: MicrobeType;
  microbeId: number;
};
const PhenoDefination = styled.span`
  display: inline-block;
  margin-bottom: 22px;
  margin-left: 19px;
`;

export const Microbe: FC<Props> = ({ location, type, microbeId }) => {
  const [showSummary, setShowSummary] = useState(true);
  const [showImages, setShowImages] = useState(true);
  const [showPhenotype, setShowPhenotype] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const microbe = useMicrobeDetails(microbeId, type);
  const category = pathogenCategory[type];

  const synonymName = isRecordLocationState(location.state)
    ? location.state.synonymName
    : undefined;

  if (isLoading(microbe)) {
    return <Loader />;
  }

  assertIsNotStoreError(microbe);

  const sections: RecordLink[] = ['Summary', 'Images', 'Phenotype', 'Downloads and references'].map(
    section => ({
      label: section,
      'aria-label': `${microbe.name} ${section.toLowerCase()}`,
      disabled: section === 'Images' && microbe.images.length === 0,
    })
  );

  const description = `GIDEON - ${microbe.name}. Notes: ${getStringFromDynamicHtml(microbe.notes)}`;

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{microbe.name} - GIDEON</title>
          <meta name="description" content={description} />
        </Helmet>
        <ReferenceLabelContext.Provider value={microbe.name}>
          <RecordLayout>
            <RecordTitle
              title={microbe.name}
              buttons={
                <SmartCompareButton
                  section={microbe.type}
                  id={microbe.code}
                  size="big"
                  showTooltip
                />
              }
              synonymName={synonymName}
              showTimeStamp={getTimeStamp(microbe.timestamp)}
            />
            <RecordLinks links={sections} />
            <RecordSection
              link={sections[0]}
              show={showSummary}
              onCollapse={() => setShowSummary(!showSummary)}
              accent="first"
              id="microbe-summary"
            >
              <MicrobeSummarySection
                pathogenCategory={category}
                etymology={microbe.etymology}
                notes={microbe.notes}
                vaccines={microbe.vaccines}
                ecology={microbe.ecology}
                synonymWithReference={microbe.synonymWithReference}
                susceptibility={microbe.drugSusceptibility}
                associated_diseases={microbe.associated_diseases}
              />
            </RecordSection>
            <RecordSection
              link={sections[1]}
              show={showImages}
              onCollapse={() => setShowImages(!showImages)}
              accent="second"
              id="microbe-images"
            >
              <ImagesSection images={microbe.images} />
            </RecordSection>
            <RecordSection
              link={sections[2]}
              show={showPhenotype}
              onCollapse={() => setShowPhenotype(!showPhenotype)}
              accent="second"
              id="microbe-phenotypes"
            >
              <PhenoDefination>
                Phenotype refers to the observable physical properties of an organism, including
                both microbial morphology and physiology.
              </PhenoDefination>
              <MicrobePhenotypesSection phenotypes={microbe.phenotype} />
            </RecordSection>
            <RecordSection
              link={sections[3]}
              show={showDownloads}
              onCollapse={() => setShowDownloads(!showDownloads)}
              accent="fourth"
              id="microbe-downloads"
            >
              <DownloadsSection id={microbeId} category={type} labelTitle={microbe.name} />
            </RecordSection>
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
