import { DiagnoseDisease } from "Diagnose/types/diagnose";

export interface ComparisonLists {
  diseases: number[];
  drugs: number[];
  bacteria: number[];
  mycobacteria: number[];
  yeasts: number[];
}
export interface IrrelevantDiseaseListsDTO {
  diseases: DiagnoseDisease[];
  drugs: number[];
  bacteria: number[];
  mycobacteria: number[];
  yeasts: number[];
}

export type ComparisonListsSection = keyof ComparisonLists;

export const isComparisonListsSection = (value: string): value is ComparisonListsSection =>
  value === 'diseases' ||
  value === 'drugs' ||
  value === 'bacteria' ||
  value === 'mycobacteria' ||
  value === 'yeasts';

export const getComparisonListsSection = (value: string): ComparisonListsSection | undefined =>
  isComparisonListsSection(value) ? value : undefined;
