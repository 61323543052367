import { ReactComponent as MailIcon } from 'assets/Start/MailinboxIcon.svg';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import { NotificationClickedStateContext } from 'services/localStorage/NotificationClicked.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import styled from 'styled-components/macro';

type MailButtonSizes = 'big' | 'normal';
type MailButtonPositions = 'right' | 'top-right' | 'left';

const MailInboxContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const MailInboxIcon = styled(Icon)`
  fill: ${props => props.theme.colors.button.hover.textColor};
  height: 50px;
`;

const NotificationBadge = styled.span`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #f4c74f;
  color: #000000;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  border-radius: 50%;
  padding: 4px 6px;
  min-width: 23px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const MailInboxIcon = styled(Icon)`
//   && {
//     fill: ${props => props.theme.colors.button.hover.textColor};
//     width: 100%;
//     height: 100%;
//     cursor: pointer;
//   }
// `;

interface Props {
  setMailOpen: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  showTooltip?: boolean;
  size?: MailButtonSizes;
  position?: MailButtonPositions;
}

export const MailInboxButton: FC<Props> = ({ setMailOpen, className, showTooltip }) => {
  const [isNotificationClicked, setIsNotificationClicked] = useContextAssert(
    NotificationClickedStateContext
  );

  const onMailClick = () => {
    setMailOpen(true);
    setIsNotificationClicked(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <MailInboxContainer onClick={onMailClick}>
        <MailInboxIcon className={className} svgComponent={MailIcon} />
        {!isNotificationClicked && <NotificationBadge>1</NotificationBadge>}
      </MailInboxContainer>
    </div>
  );
};

// export const MailInboxButton = styled(BaseMailInboxButton)`
//   ${props =>
//     props.size === 'big'
//       ? css`
//           width: 35px;
//           height: 35px;
//         `
//       : css`
//           width: 20px;
//           height: 20px;
//         `}

//   padding: 0;
//   position: relative;
//   user-select: none;
//   flex: 0 0 auto;

//   &:hover {
//   }
// `;
