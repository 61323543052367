export type Dictionary<T> = { [key: string]: T };

export type PaginationData = {
  total: number;
  offset: number;
  limit: number;
};

export type Timestamp = number | undefined;

export const Loading = 'Loading';
export type LoadingType = typeof Loading;
export const isLoading = (state: unknown): state is LoadingType => state === Loading;
export const isNotLoading = <T>(value: T): value is Exclude<T, LoadingType> => !isLoading(value);

export const pathogenCategory: Record<string, string> = {
  bacteria: 'Bacterium',
  fungi: 'Filamentous fungus',
  parasites: 'Parasite',
  mycobacteria: 'Mycobacterium',
  viruses: 'Virus',
  yeasts: 'Yeast or Alga',
};

export const isDisabled = (data: any): boolean => {
  let flag = true;
  for (let i = 0; i < data.length; i++) {
    flag = true; // check till the last value
    if (data[i] && data[i].length) {
      flag = false;
      break;
    }
  }
  return flag;
};

export const scrollToElementById = (elementId: string, headerOffset = 50) => {
  const scrollToElement = () => {
    const element = document.getElementById(elementId);

    if (element) {
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });

      element.style.border = `2px solid #F4C74F`;

      setTimeout(() => {
        element.style.border = "";
      }, 1500);

      return true; 
    }
    return false;
  };

  // Try scrolling immediately, then retry every 300ms until found (max 10 attempts)
  let attempts = 0;
  const interval = setInterval(() => {
    if (scrollToElement() || attempts > 10) {
      clearInterval(interval);
    }
    attempts++;
  }, 300);
};



