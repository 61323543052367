import { ShadowButton } from 'Molecules/buttons/ShadowButton';
import React, { FC, ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import { useEventListener } from 'services/useEventListener.hook';
import styled, { css } from 'styled-components/macro';
import { ScrollbarMixin } from 'utils/styleMixins';

const Wrapper = styled.div`
  position: relative;
`;

export const Scroll = styled.div<{ fullHeight: boolean }>`
  position: relative;
  overflow: auto;

  ${props =>
    props.fullHeight
      ? ''
      : css`
          min-height: 300px;
          max-height: 880px;
        `}

  ${ScrollbarMixin}
`;

interface Props {
  className?: string;
  children: ReactNode;
  fullHeight?: boolean;
}

interface ShadowState {
  shadowLeftOpacity: number;
  shadowRightOpacity: number;
}

export const HorizontalScrollbarShadows: FC<Props> = ({
  className = '',
  children,
  fullHeight = true,
}) => {
  const [scrollRef, setScrollRef] = useState<HTMLDivElement | null>(null);
  const [shadowState, setShadowState] = useState<ShadowState>({
    shadowLeftOpacity: 0,
    shadowRightOpacity: 0,
  });
  const [horizontalScrollbarHeight, setHorizontalScrollbarHeight] = useState<number>(0);
  const [verticalScrollbarWidth, setVerticalScrollbarWidth] = useState<number>(0);

  const onUpdate = useCallback((): void => {
    const {
      scrollLeft = 0,
      scrollWidth = 0,
      clientWidth = 0,
      clientHeight = 0,
      offsetHeight = 0,
      offsetWidth = 0,
    } = scrollRef || {};

    if (horizontalScrollbarHeight !== offsetHeight - clientHeight) {
      setHorizontalScrollbarHeight(offsetHeight - clientHeight);
    }

    if (verticalScrollbarWidth !== offsetWidth - clientWidth) {
      setVerticalScrollbarWidth(offsetWidth - clientWidth);
    }

    const shadowLeftOpacity = scrollLeft > 0 ? 1 : 0;
    const availableScrollSpace = scrollWidth - clientWidth;
    const shadowRightOpacity = availableScrollSpace - scrollLeft > 0 ? 1 : 0;
    if (
      shadowState.shadowLeftOpacity !== shadowLeftOpacity ||
      shadowState.shadowRightOpacity !== shadowRightOpacity
    ) {
      setShadowState({ shadowLeftOpacity, shadowRightOpacity });
    }
  }, [
    horizontalScrollbarHeight,
    scrollRef,
    shadowState.shadowLeftOpacity,
    shadowState.shadowRightOpacity,
    verticalScrollbarWidth,
  ]);

  useEventListener('resize', onUpdate);
  useLayoutEffect(onUpdate);

  const onScrollClick = (toRight?: boolean): void => {
    const distance = toRight ? 300 : -300;
    if (scrollRef !== null) {
      const ref = scrollRef;
      if (ref.scrollBy) {
        ref.scrollBy({
          left: distance,
          behavior: 'smooth',
        });
      } else {
        ref.scrollLeft = distance + ref.scrollLeft;
      }
    }
  };

  return (
    <Wrapper className={className}>
      <Scroll ref={setScrollRef} onScroll={onUpdate} fullHeight={fullHeight} tabIndex={0}>
        {children}
      </Scroll>
      <ShadowButton
        onClick={() => onScrollClick()}
        bottomOffset={horizontalScrollbarHeight}
        opacity={shadowState.shadowLeftOpacity}
      />
      <ShadowButton
        right
        onClick={() => onScrollClick(true)}
        bottomOffset={horizontalScrollbarHeight}
        rightOffset={verticalScrollbarWidth}
        opacity={shadowState.shadowRightOpacity}
      />
    </Wrapper>
  );
};
