import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Label } from 'Atoms/Label';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { Span } from 'Atoms/text';
import { H2 } from 'Atoms/text/H';
import {
  promotionSignUp,
  // getAccountEmailExists
} from 'Auth/apiServices/account';
import { SignUpError } from 'Auth/apiServices/account.dto';
import { FormInput } from 'Auth/Molecules/FormInput';
import { FormMessage } from 'Auth/Molecules/FormMessage';
import { SocialProof } from 'Auth/Molecules/SocialProof';
import {
  ButtonRow,
  ContentContainer,
  DateInputRow,
  DatePickerStyled,
  ErrorRow,
  GraduationErrorRow,
  GraduationRow,
  GraduationTrialLayout,
  Row,
  Title,
} from 'Auth/Molecules/Styles';
import { emailRegex, validateEmailDomain } from 'Auth/services/validation';
import { initialState, State } from 'Auth/store/GraduationSignupStore/provider';
import { reducer } from 'Auth/store/GraduationSignupStore/reducer';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import moment, { Moment } from 'moment';
import { DecodedToken, DecodedTokenUser } from 'Organisms/ReferenceCard';
import React, {
  FC,
  FormEvent,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { AuthTokenContext } from 'services/localStorage/AuthToken.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { usePushState } from 'services/usePushState.hook';
import styled from 'styled-components/macro';
// import { EmailExists } from 'types/account';

const FormContainer = styled.div`
  width: 400px;
  height: 100%;
  @media (max-width: ${props => props.theme.breakpoints.m}) {
    width: 100%;
  }
`;

const Form = styled.form`
  width: 380px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    position: unset;
    width: 100%;

    align-items: center;
  }
`;

const TrialFormInputSpaced = styled(FormInput)`
  margin-left: 5px;
`;

const SpecialButton = styled(FilledButton)`
  width: 450px;
  height: 44px;
  margin-top: 20px;
`;

const CancelButton = styled(FilledButton)`
  width: 450px;
  height: 44px;
  margin-left: 10px;
  margin-top: 20px;
`;

export const GraduationSignUpHome: FC = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useContextAssert(AuthTokenContext);
  const [userTokenRef, setUserToken] = useState<DecodedTokenUser>();

  useEffect(() => {
    if (token) {
      try {
        const decoded: DecodedToken = jwtDecode(token);
        setUserToken(decoded.user);
      } catch (error) {
        console.error('Failed to decode token', error);
      }
    }
  }, [token]);

  const graduationStartDate = moment('2025-03-01');
  const graduationEndDate = moment('2026-04-30');

  const isOutsideStartRange = (date: Moment): boolean => {
    return date.isBefore(graduationStartDate) || date.isAfter(graduationEndDate);
  };

  const { push } = usePushState();

  const validateEmail = useCallback((): string | null => {
    if (!state.email) {
      return 'Email is required';
    } else {
      const emailIsValid = state.email.match(emailRegex);

      if (!emailIsValid) {
        return 'Email is not valid';
      }
      // else {
      //   const isValid = validateEmailDomain(state.email);

      //   if (!isValid) {
      //     return 'Please use an institutional email account';
      //   }
      // }
    }

    return null;
  }, [state.email]);

  const validateForm = (): State['errors'] => {
    const errors: State['errors'] = {
      firstName: null,
      lastName: null,
      email: null,
      form: null,
      graduationDate: null,
    };

    if (!state.firstName) {
      errors.firstName = 'First name is required';
    }

    if (!state.lastName) {
      errors.lastName = 'Last name is required';
    }

    if (!state.graduationDate) {
      errors.graduationDate = 'Graduation date is required';
    }

    const emailError = validateEmail();

    if (emailError) {
      errors.email = emailError;
    }

    return errors;
  };

  const getFormError = (message: string | undefined): ReactNode => {
    if (message === 'Email already exists.') {
      return (
        <Span>
          Email already exists. Click <Link to="/login">here</Link> to sign in.
        </Span>
      );
    } else if (message === 'You are not human.') {
      return 'Suspicious behaviour detected. Please try again.';
    }

    return message || 'Server error';
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const { firstName, lastName, email, graduationDate } = state;

    const errors = validateForm();
    dispatch({ type: 'SignUp/Set/Errors', payload: errors });

    if (Object.values(errors).some(error => error)) {
      return;
    }

    setIsLoading(true);

    promotionSignUp(
      firstName,
      lastName,
      email,
      moment(graduationDate).format('YYYY-MM-DD'),
      userTokenRef?.institutionId
    )
      .then(() => {
        setIsLoading(false);
        push('graduation-signup/success', { email: email });
      })
      .catch((e: AxiosError<SignUpError> | null) => {
        dispatch({
          type: 'SignUp/Set/Error',
          payload: { field: 'form', value: getFormError(e?.response?.data?.error?.message) },
        });
        setIsLoading(false);
      });
  };

  return (
    <GraduationTrialLayout>
      <Helmet>
        <title>Graduation Signup-GIDEON</title>
      </Helmet>
      <Title color="lightLink" weight="500" size="big" font="Inter">
        Step into your medical career with confidence
      </Title>
      {/* <ComingSoon>Work In Progress</ComingSoon> */}
      <H2 weight="500" size="smallRegular" font="Inter">
        <Span size="medium" weight="700">
          Congratulations on your upcoming graduation!
        </Span>{' '}
        Kickstart your journey with a free year of
        <br />
        GIDEON, your trusted companion for infectious disease knowledge and diagnostics.
        <br />
        <Span size="medium" fontStyle="italic" weight="600" color="giftText">
          This exclusive gift, worth $2,548, is our way of celebrating your achievement.
        </Span>
        <br />
        Stay ahead with real-time data, expert insights, and powerful tools at your fingertips.
      </H2>
      <ContentContainer>
        <FormContainer>
          <Form onSubmit={onSubmit}>
            <GraduationRow error={!!state.errors.firstName || !!state.errors.lastName}>
              <FormInput
                id="signup-first-name"
                label="First name"
                value={state.firstName}
                onChange={firstName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'firstName', value: firstName },
                  })
                }
                error={!!state.errors.firstName}
                autoComplete="given-name"
              />
              <TrialFormInputSpaced
                id="signup-last-name"
                label="Last name"
                value={state.lastName}
                onChange={lastName =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'lastName', value: lastName },
                  })
                }
                error={!!state.errors.lastName}
                autoComplete="family-name"
              />
            </GraduationRow>
            <GraduationErrorRow>
              <FormMessage type="error" message={state.errors.firstName} />
            </GraduationErrorRow>
            <GraduationErrorRow>
              <FormMessage type="error" message={state.errors.lastName} />
            </GraduationErrorRow>
            <GraduationRow error={!!state.errors.email}>
              <FormInput
                id="signup-email"
                label="Email (to be used for login after graduation)"
                value={state.email}
                onChange={email =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'email', value: email.trim() },
                  })
                }
                error={!!state.errors.email}
                type="email"
                autoComplete="email"
              />
            </GraduationRow>
            <GraduationErrorRow>
              <FormMessage type="error" message={state.errors.email} />
            </GraduationErrorRow>
            <DateInputRow>
              <Label size="small" weight="700" htmlFor={'graduation-date'}>
                Your graduation date
              </Label>
              <DatePickerStyled
                id="graduation-date"
                value={state.graduationDate}
                onChange={date =>
                  dispatch({
                    type: 'SignUp/Set/Value',
                    payload: { field: 'graduationDate', value: date },
                  })
                }
                hasError={!!state.errors.graduationDate}
                placeholder="DD/MM/YYYY"
                isOutsideRange={isOutsideStartRange}
                open_direction="up"
              />
            </DateInputRow>
            <GraduationErrorRow>
              <FormMessage type="error" message={state.errors.graduationDate} />
            </GraduationErrorRow>
            <GraduationErrorRow>
              <FormMessage type="error" message={state.errors.form} />
            </GraduationErrorRow>
            <ButtonRow>
              <SpecialButton weight="700" color="special">
                {isLoading ? <Loader /> : 'Claim your gift'}
              </SpecialButton>
              <CancelButton onClick={() => push('/login')} type="button">
                {isLoading ? <Loader /> : 'Cancel'}
              </CancelButton>
            </ButtonRow>
          </Form>
        </FormContainer>
      </ContentContainer>
    </GraduationTrialLayout>
  );
};
