import { P, Props as PProps } from 'Atoms/text/P';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { synonymWithReferenceDTO } from 'types/disease';
import { groupDataAlphabeticallyForSynonym } from 'utils/getGroupedData';

import { ReferenceSpan } from './DiseaseSummarySection';

const Wrapper = styled.div`
  column-count: 5;
  column-width: 200px;
  column-gap: 20px;
`;

const Group = styled.div`
  break-inside: avoid-column;
  display: table;
  margin: 20px 0 0 0;
  &:first-child {
    margin-top: 0;
  }
`;

const GroupTitle = styled(P).attrs(
  (): Partial<PProps> => ({
    weight: '700',
    size: 'normal',
  })
)``;

const GroupItem = styled(P).attrs(
  (): Partial<PProps> => ({
    size: 'normal',
  })
)``;

export const ReferenceWithLink = styled.div`
  display: flex;
`;

interface Props {
  data: synonymWithReferenceDTO[];
}

export const TradeNamesSection: FC<Props> = ({ data }) => {
  const groupedList = groupDataAlphabeticallyForSynonym(data, true);

  return (
    <Wrapper>
      {Object.keys(groupedList)
        .sort()
        .map((key: string) => (
          <Group key={key}>
            <GroupTitle>{key}</GroupTitle>
            {groupedList[key].map((item, i) => (
              <ReferenceWithLink key={`${item.synonym}-${i}`}>
                <GroupItem key={item.toString()}>{item.synonym}</GroupItem>
                {item.references && (
                  <ReferenceSpan>
                    <DynamicHtml content={item.references} />
                  </ReferenceSpan>
                )}
              </ReferenceWithLink>
            ))}
          </Group>
        ))}
    </Wrapper>
  );
};
