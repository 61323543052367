import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { GraduationSignUpHome } from './Home';
import { SignUpSuccess } from './Success';

export const GraduationSignUp: FC = () => (
  <Switch>
    <Route exact path="/graduation-signup" component={GraduationSignUpHome} />
    <Route exact path="/graduation-signup/success" component={SignUpSuccess} />
  </Switch>
);
