import { Link } from 'Atoms/links/Link';
import { OrderedList, OrderedListItem } from 'Atoms/OrderedList';
import { P } from 'Atoms/text';
import { CompleteTooltip } from 'Atoms/tooltip/CompleteTooltip';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { synonymWithReferenceDTO } from 'types/disease';
import { DynamicContent } from 'types/dynamicContent';
import {
  RelatedDiseaseWithReference,
  Reservoir_Vector_Vehicle_WithReference,
  VirusDrugWithReference,
  VirusVaccinesWithReference,
} from 'types/microbeDetails';
import { getColumnCount, getWidth } from 'utils/getColumnWidthAndCount';

import { ReferenceSpan, Section, StyledList } from './DiseaseSummarySection';
import { SummaryCategorySection } from './SummaryCategorySection';

const Wrapper = styled.div``;

const Category = styled.div`
  margin: 30px 0;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const ReferenceWithLink = styled.li`
  display: flex;
`;

const StyledPBase = styled(P)`
  position: relative;
  display: inline-block;
  margin: 20px 0 0;
`;

const StyledP = styled(P)`
  margin-bottom: 10px;
`;

const SynonymsHeading = styled(StyledPBase)`
  margin: 10px 0;
`;

const StyledTooltip = styled(CompleteTooltip)`
  margin-top: -10px;
  margin-left: 1px;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.colors.text.darkLink};
  font: ${props => props.theme.fonts.normalText};
  padding-right: 5px;
  margin-bottom: 5px;
  // display: flex;
  // align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

interface Props {
  diseases: RelatedDiseaseWithReference[];
  reservoirs?: Reservoir_Vector_Vehicle_WithReference[] | null;
  vehicles?: Reservoir_Vector_Vehicle_WithReference[] | null;
  vectors?: Reservoir_Vector_Vehicle_WithReference[] | null;
  vaccines?: VirusVaccinesWithReference[] | null;
  drugs?: VirusDrugWithReference[] | null;
  synonymWithReference?: synonymWithReferenceDTO[] | null;
  etymology?: DynamicContent;
  pathogenCategory?: string;
}

export const ParasiteFungusSummarySection: FC<Props> = ({
  diseases,
  reservoirs,
  vaccines,
  vehicles,
  vectors,
  drugs,
  synonymWithReference,
  etymology,
  pathogenCategory,
}) => {
  return (
    <Wrapper>
      {pathogenCategory && (
        <Category>
          <StyledP weight="700">Category</StyledP>
          <>{pathogenCategory}</>
        </Category>
      )}
      {reservoirs && (
        <SummaryCategorySection
          data={reservoirs}
          title="Reservoir"
          tooltipContent="Any animal, arthropod, plant, soil or substance in which an infectious agent normally lives and multiplies, on which it depends primarily for survival, and where it reproduces itself in such a manner that it can be transmitted to a susceptible host."
        />
      )}
      {vehicles && (
        <SummaryCategorySection
          data={vehicles}
          title="Vehicles"
          tooltipContent="The mode of transmission for an infectious agent. This generally implies a passive and inanimate (i.e., non-vector) mode."
        />
      )}
      {vectors && (
        <SummaryCategorySection
          data={vectors}
          title="Vector"
          tooltipContent="An arthropod or other living carrier which transports an infectious agent from an infected organism or reservoir to a susceptible individual or immediate surroundings."
        />
      )}
      {drugs && (
        <Category>
          <StyledP weight="700">
            Drugs
            <StyledTooltip content="Drugs which have been advocated for the therapy of infection caused by the virus." />
          </StyledP>
          {drugs.map(d => (
            <ReferenceWithLink key={d.drug_code}>
              <StyledLink key={d.drug_code} to={`/explore/drugs/${d.drug_code}`}>
                {d.drug}{' '}
              </StyledLink>
              {d.references.length > 0 && <DynamicHtml content={d.references} />}
            </ReferenceWithLink>
          ))}
        </Category>
      )}
      {vaccines && (
        <Category>
          <StyledP weight="700">
            Vaccines
            <StyledTooltip content="Vaccines and globulin preparations." />
          </StyledP>
          {vaccines.map(d => (
            <ReferenceWithLink key={d.vaccine_code}>
              <StyledLink key={d.vaccine_code} to={`/explore/vaccines/${d.vaccine_code}`}>
                {d.vaccine}{' '}
              </StyledLink>
              {d.references.length > 0 && <DynamicHtml content={d.references} />}{' '}
            </ReferenceWithLink>
          ))}
        </Category>
      )}
      <Category>
        {etymology && etymology.length > 0 && (
          <Section>
            <SynonymsHeading weight="700">Etymology</SynonymsHeading>
            <div>
              <DynamicHtml content={etymology} />
            </div>
          </Section>
        )}
      </Category>
      {diseases && diseases.length > 0 && (
        <Section>
          <StyledP weight="700">
            {diseases.length > 1
              ? `Associated Diseases (${diseases.length})`
              : 'Associated Disease'}
          </StyledP>
          <StyledList
            key={'associated-disease-list'}
            containerWidth={getWidth(diseases.length)}
            columnCount={getColumnCount(diseases.length)}
          >
            {diseases.map(x => (
              <ReferenceWithLink key={x.disease}>
                <StyledLink key={x.disease_code} to={`/explore/diseases/${x.disease_code}`}>
                  {x.disease}
                </StyledLink>
                {x.references && <DynamicHtml content={x.references} />}
              </ReferenceWithLink>
            ))}
          </StyledList>
        </Section>
      )}
      {synonymWithReference && (
        <Category>
          <StyledP weight="700">Related terms ({synonymWithReference.length})</StyledP>
          <OrderedList>
            {synonymWithReference.map((s, i) => (
              <OrderedListItem key={i}>
                {s.synonym}
                {s.references && (
                  <ReferenceSpan>
                    <DynamicHtml content={s.references} />
                  </ReferenceSpan>
                )}
              </OrderedListItem>
            ))}
          </OrderedList>
        </Category>
      )}
    </Wrapper>
  );
};
