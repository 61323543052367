import { ReactComponent as CombineIcon } from 'assets/UI/Combine.svg';
import { ReactComponent as StarIcon } from 'assets/UI/Star.svg';
import { ReactComponent as SurgeryIcon } from 'assets/UI/Surgery.svg';
import { InvisibleButton } from 'Atoms/buttons/InvisibleButton';
import { Icon } from 'Atoms/Icon';
import React, { FC } from 'react';
import { getKeyInfo } from 'services/compare/getCompareDrugData';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

const IconStyled = styled(Icon)`
  margin-left: 10px;
`;

const DrugKeyWrapper = styled(InvisibleButton)`
  cursor: pointer;
  outline: none;

  fill: ${props => props.theme.colors.text.lightLink};
  & > ${IconStyled} {
    fill: ${props => props.theme.colors.text.lightLink};
  }

  &:hover {
    fill: ${props => props.theme.colors.text.lightLink};
    & > ${IconStyled} {
      fill: ${props => props.theme.colors.text.lightLink};
    }
  }
`;

interface Props {
  show: boolean;
  type: 'Star' | 'Combine' | 'Surgery';
  ariaLabel?: string;
}

export const DrugSpectrumKey: FC<Props> = ({ show, type, ariaLabel }) => {
  const [props, popper, setElement] = useTooltip(getKeyInfo(type));

  const getIcon = (): FC => {
    switch (type) {
      case 'Star':
        return StarIcon;
      case 'Combine':
        return CombineIcon;
      case 'Surgery':
        return SurgeryIcon;
    }
  };

  return show ? (
    <div {...props.events}>
      <DrugKeyWrapper {...props.aria} ref={setElement} aria-label={ariaLabel}>
        <IconStyled svgComponent={getIcon()} size="small" />
      </DrugKeyWrapper>
      {popper}
    </div>
  ) : null;
};
