import { CollapseButton } from 'Atoms/buttons/CollapseButton';
import React, { forwardRef, ReactNode } from 'react';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

const Top = styled.div`
  display: flex;
  border-bottom: 1px solid ${props => props.theme.colors.separator.dark};
  padding: 5px 0;
`;

interface Props {
  className?: string;
  children?: ReactNode;
  show: boolean;
  onClick: () => void;
  label: string;
}

export const SectionTitle = forwardRef<HTMLDivElement, Props>(
  ({ className, show, onClick, children, label }, ref) => {
    const tooltipText = show
      ? `Collapse ${label.toLowerCase()} section`
      : `Expand ${label.toLowerCase()} section`;
    const [props, popper] = useTooltip(tooltipText, { eventElementSelector: true });

    return (
      <Top className={className} ref={ref}>
        {children}
        <div {...props.events}>
          <CollapseButton
            onClick={onClick}
            isCollapsed={!show}
            {...props.aria}
            ariadescribedby="collapse-button"
            ariaLabel="toggle-content"
          />
          {popper}
        </div>
      </Top>
    );
  }
);
