import { ReactComponent as GraphSVG } from 'assets/Button/Graph.svg';
import { ReactComponent as DiseasesIcon } from 'assets/ExploreFocus/Diseases.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Link } from 'Atoms/links/Link';
import { Loader } from 'Atoms/Loader';
import { H2, P } from 'Atoms/text';
import { MainLayout } from 'layouts/MainLayout';
import { RecordLayout } from 'layouts/RecordLayout';
import { DynamicRecordLink, RecordLinks } from 'Molecules/record/RecordLinks';
import { RecordSection } from 'Molecules/record/RecordSection';
import { RecordTitle } from 'Molecules/record/RecordTitle';
import { DownloadsSection } from 'Molecules/section/DownloadsSection';
import { VaccinationSection } from 'Molecules/section/VaccinationSection';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import { ReferenceLabelContext } from 'Organisms/dynamicContent/ReferenceMarker';
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { getLatestOutbreakLabel, getTimeStamp } from 'services/getLatestOutbreakLabel';
import { ReferenceProvider } from 'services/referenceProvider/ReferenceProvider';
import { usePushState } from 'services/usePushState.hook';
import { useCountriesResource } from 'store/countriesStore/hooks';
import { useCountryLatestOutbreaks } from 'store/countryLatestOutbreaksStore/hooks';
import { useCountryNotes } from 'store/countryNotesStore/hooks';
import { useDiseasesResource } from 'store/diseasesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';
import { scrollToElementById } from 'types/common';
import { CustomError, ErrorCodes } from 'types/errorTypes';

const StyledButton = styled(FilledButton)`
  min-width: 48px;
  min-height: 48px;
  margin-left: 10px;
  max-width: 48px;
  max-height: 48px;
  & > span {
    display: flex;
    flex-direction: column;
  }
`;

export const Schedule = styled.h3`
  margin: auto;
  margin-bottom: 5px;
`;

const StyledIcon = styled(Icon)`
  width: 18px;
  height: 18px;
  margin-bottom: 4px;
`;

const NoDataTitle = styled(H2)`
  text-align: left;
  padding: 10px 43px;
  margin: 0;

  @media (max-width: ${props => props.theme.breakpoints.m}) {
    padding: 10px 28px;
  }
`;

type Props = RouteComponentProps & {
  diseaseId: number;
  countryId: string;
};

const generateLabel = (
  type: string,
  graphCount: number | undefined,
  dataCount: number | undefined
): string => {
  let dataLabel = '';
  const graphLabel = graphCount === 1 ? 'graph' : 'graphs';

  if (type === 'Outbreaks' || type === 'Crossborder') {
    dataLabel = dataCount === 1 ? 'event' : 'events';
  } else if (type === 'Prevalence' || type === 'Seroprevalence') {
    dataLabel = dataCount === 1 ? 'survey' : 'surveys';
  }

  if (graphCount && dataCount) {
    return `${type} (${graphCount} ${graphLabel}, ${dataCount} ${dataLabel})`;
  }
  if (graphCount) {
    return `${type} (${graphCount} ${graphLabel})`;
  }
  if (dataCount) {
    return ` ${type} (${dataCount} ${dataLabel})`;
  }
  return type;
};

export const DiseaseInCountry: FC<Props> = ({ match, countryId, diseaseId }) => {
  const location = useLocation();
  const { push } = usePushState();
  const [showBackground, setShowBackground] = useState(true);
  const [showGraph, setShowGraph] = useState(true);
  const [showDrugResistance, setShowDrugResistance] = useState(true);
  const [showTransmission, setShowTransmission] = useState(true);
  const [showHumanIncidence, setShowHumanIncidence] = useState(true);
  const [showAnimalIncidence, setShowAnimalIncidence] = useState(true);
  const [showVaccinations, setShowVaccinations] = useState(true);
  const [showPrevalenceSurveys, setShowPrevalenceSurveys] = useState(true);
  const [showSeroprevalenceSurveys, setShowSeroprevalenceSurveys] = useState(true);
  const [showCrossBorder, setShowCrossBorder] = useState(true);
  const [showOutbreaks, setShowOutbreaks] = useState(true);
  const [showCaseSeries, setShowCaseSeries] = useState(true);
  const [showSporadic, setShowSporadic] = useState(true);
  const [showRelatedDisease, setShowRelatedDisease] = useState(true);
  const [showRelatedCountries, setShowRelatedCountries] = useState(true);
  const [showReviewAndUpdates, setShowReviewAndUpdates] = useState(true);
  const [showDownloads, setShowDownloads] = useState(true);

  const diseases = useDiseasesResource();
  const countries = useCountriesResource();
  const countryNotes = useCountryNotes(diseaseId, countryId);
  const latestOutbreaks = useCountryLatestOutbreaks(countryId);

  useEffect(() => {
    if (
      !isLoading(diseases) &&
      !isLoading(countries) &&
      !isLoading(countryNotes) &&
      !isLoading(latestOutbreaks)
    ) {
      assertIsNotStoreError(diseases);
      assertIsNotStoreError(countries);
      assertIsNotStoreError(countryNotes);

      if (location.hash) {
        const targetId = location.hash.substring(1);
        const element = document.getElementById(targetId);
        if (element) {
          scrollToElementById(targetId);
        }
      }
    }
  }, [countryNotes]);

  if (
    isLoading(diseases) ||
    isLoading(countries) ||
    isLoading(countryNotes) ||
    isLoading(latestOutbreaks)
  ) {
    return <Loader />;
  }

  assertIsNotStoreError(diseases);
  assertIsNotStoreError(countries);
  assertIsNotStoreError(countryNotes);

  const disease = diseases.data[diseaseId].name;
  const country = countries.data.find(x => x.id === countryId);

  if (!country) {
    throw new CustomError(
      `Country ${countryId} for disease ${diseaseId} not found`,
      ErrorCodes.NotFound
    );
  }

  const noteForUK =
    countryId === 'G291' ? (
      <>
        <P>
          In many cases, data published for the United Kingdom include reports from England and
          Wales only.
        </P>
        <P>
          As such, <Link to={`/explore/diseases/${diseaseId}/G357`}>Northern Ireland</Link> and{' '}
          <Link to={`/explore/diseases/${diseaseId}/G327`}>Scotland</Link> are listed as additional
          countries.
        </P>
      </>
    ) : undefined;

  const title = `${disease} in ${country.name}`;

  const sections: DynamicRecordLink[] = [
    {
      label: 'Background',
      'aria-label': title,
      intro: countryNotes.allSection[`background_intro`],
      accent: 'first',
      disabled: countryNotes.allSection.background ? false : true,
    },
    {
      label: `Graphs (${countryNotes.allSection.graphs?.length})`,
      'aria-label': `${disease} graphs`,
      accent: 'first',
      disabled: countryNotes.allSection.graphs?.length ? false : true,
    },
    {
      label: `Drug resistance`,
      'aria-label': `${disease} graphs`,
      accent: 'first',
      disabled: countryNotes.allSection.drug_resistance?.length ? false : true,
    },
    {
      label: countryNotes.allSection.vaccination_graphs?.length
        ? `Vaccinations (${countryNotes.allSection.vaccination_graphs?.length} graphs)`
        : 'Vaccinations',
      'aria-label': `${disease} vaccinations`,
      accent: 'first',
      disabled: countryNotes.allSection.vaccinations ? false : true,
    },
    {
      label: 'Transmission',
      'aria-label': `${disease} transmission`,
      accent: 'second',
      disabled: countryNotes.allSection.transmission?.length ? false : true,
    },
    {
      label: generateLabel(
        'Incidence in humans',
        countryNotes.allSection.incidenceInHumansGraphs?.length,
        undefined
      ),
      'aria-label': `${disease} incidence in human`,
      accent: 'second',
      disabled:
        countryNotes.allSection.incidenceInHumans || countryNotes.allSection.incidenceInHumansGraphs
          ? false
          : true,
    },
    {
      label: generateLabel(
        'Incidence in animals',
        countryNotes.allSection.incidenceInAnimalsGraphs?.length,
        undefined
      ),
      'aria-label': `${disease} incidence in animals`,
      accent: 'second',
      disabled:
        countryNotes.allSection.incidenceInAnimals ||
        countryNotes.allSection.incidenceInAnimalsGraphs?.length
          ? false
          : true,
    },
    {
      label: generateLabel(
        'Prevalence',
        countryNotes.allSection.prevalenceGraph?.length,
        countryNotes.prevalence?.length
      ),
      'aria-label': `${disease} prevalence surveys`,
      accent: 'second',
      disabled:
        countryNotes.prevalence.length > 0 || countryNotes.allSection.prevalenceGraph
          ? false
          : true,
    },
    {
      label: generateLabel(
        'Seroprevalence',
        countryNotes.allSection.seroprevalenceGraph?.length,
        countryNotes.seroprevalence?.length
      ),
      'aria-label': `${disease} prevalence surveys`,
      accent: 'third',
      disabled:
        countryNotes.seroprevalence.length > 0 || countryNotes.allSection.seroprevalenceGraph
          ? false
          : true,
    },
    {
      label: generateLabel('Crossborder', undefined, countryNotes.crossborders?.length),
      'aria-label': `${disease} cross-border events`,
      intro: countryNotes.allSection[`crossborder_intro`],
      accent: 'third',
      disabled: countryNotes.allSection.crossborder?.length ? false : true,
    },
    {
      label: `Sporadic events (${countryNotes.sporadic?.length})`,
      'aria-label': `${disease} Sporadic events`,
      intro: countryNotes.allSection[`sporadic_intro`],
      accent: 'third',
      disabled: countryNotes.allSection.sporadic?.length ? false : true,
    },
    {
      label: generateLabel(
        'Outbreaks',
        countryNotes.allSection.outbreakGraphs?.length,
        countryNotes.outbreaks?.length
      ),
      'aria-label': `${disease} outbreaks`,
      intro: countryNotes.allSection[`outbreaks_intro`],
      accent: 'third',
      disabled:
        countryNotes.allSection.outbreaks || countryNotes.allSection.outbreakGraphs ? false : true,
    },
    {
      label: `Case series (${countryNotes.caseSeries?.length})`,
      'aria-label': `${disease} case series`,
      accent: 'fourth',
      disabled: countryNotes.caseSeries?.length ? false : true,
    },
    {
      label: `Related Diseases (${countryNotes.relatedDiseases?.length})`,
      'aria-label': `${disease} Related diseases`,
      accent: 'fourth',
      disabled: countryNotes.relatedDiseases?.length ? false : true,
    },
    {
      label: `Related Countries (${countryNotes.relatedCountries?.length})`,
      'aria-label': `${disease} Related countries`,
      accent: 'fourth',
      disabled: countryNotes.relatedCountries?.length ? false : true,
    },
    {
      label: 'Reviews and updates',
      'aria-label': `${disease} Reviews and updates`,
      intro: countryNotes.allSection[`reviews_and_updates_intro`],
      accent: 'fourth',
      disabled: countryNotes.allSection.reviews_and_updates?.length ? false : true,
    },
    {
      label: 'Downloads and references',
      accent: 'fifth',
      'aria-label': `${disease} worldwide downloads and references`,
    },
  ];

  return (
    <ReferenceProvider>
      <MainLayout>
        <Helmet>
          <title>{title} - GIDEON</title>
        </Helmet>
        <ReferenceLabelContext.Provider value={title}>
          <RecordLayout>
            <RecordTitle
              title={title}
              buttons={
                <>
                  <StyledButton
                    onClick={() => {
                      const path = match.url.split('/');
                      push(path.filter((_, i) => i < path.length - 1).join('/'));
                    }}
                    size="micro"
                  >
                    <StyledIcon svgComponent={DiseasesIcon} />
                    Disease
                  </StyledButton>

                  <StyledButton
                    padding="none"
                    size="micro"
                    onClick={() =>
                      push(`/visualize/graphs?disease=${diseaseId}&country=${countryId}`)
                    }
                  >
                    <StyledIcon svgComponent={GraphSVG} />
                    Stats
                  </StyledButton>
                </>
              }
              note={noteForUK}
              subtitle={getLatestOutbreakLabel(diseaseId, latestOutbreaks.data)}
              showTimeStamp={getTimeStamp(countryNotes.timestamp)}
            />
            {countryNotes.note && countryNotes.note.length > 0 ? (
              <>
                <RecordLinks links={sections} />
                {/* Background */}
                {countryNotes.allSection.background && (
                  <RecordSection
                    link={sections[0]}
                    show={showBackground}
                    onCollapse={() => setShowBackground(!showBackground)}
                    accent={sections[0].accent}
                    id="Background"
                  >
                    {sections[0].intro && <DynamicHtml content={sections[0].intro} />}
                    <DynamicHtml content={countryNotes.allSection.background} />
                  </RecordSection>
                )}
                {/* Graphs */}
                {countryNotes.allSection.graphs && (
                  <RecordSection
                    link={sections[1]}
                    show={showGraph}
                    onCollapse={() => setShowGraph(!showGraph)}
                    accent={sections[1].accent}
                    id="Graphs"
                  >
                    {countryNotes.allSection.graphs.map((graph, index) => (
                      <DynamicHtml content={graph} key={index} />
                    ))}
                  </RecordSection>
                )}
                {/* Drug resistance */}
                {countryNotes.allSection.drug_resistance && (
                  <RecordSection
                    link={sections[2]}
                    show={showDrugResistance}
                    onCollapse={() => setShowDrugResistance(!showDrugResistance)}
                    accent={sections[2].accent}
                    id="Drug resistance"
                  >
                    <DynamicHtml content={countryNotes.allSection.drug_resistance} />
                  </RecordSection>
                )}
                {/* Vaccinations */}
                {countryNotes.allSection.vaccinations && (
                  <RecordSection
                    link={sections[3]}
                    show={showVaccinations}
                    onCollapse={() => setShowVaccinations(!showVaccinations)}
                    accent={sections[3].accent}
                    id="Vaccinations"
                  >
                    <Schedule>Schedule</Schedule>
                    <VaccinationSection data={[countryNotes.allSection.vaccinations]} />
                    {countryNotes.allSection.vaccinations_intro && (
                      <DynamicHtml content={countryNotes.allSection.vaccinations_intro} />
                    )}
                    {countryNotes.allSection.vaccination_graphs &&
                      countryNotes.allSection.vaccination_graphs.map((graph, index) => (
                        <DynamicHtml content={graph} key={index} />
                      ))}
                  </RecordSection>
                )}
                {/* Transmission */}
                {countryNotes.allSection.transmission && (
                  <RecordSection
                    link={sections[4]}
                    show={showTransmission}
                    onCollapse={() => setShowTransmission(!showTransmission)}
                    accent={sections[4].accent}
                    id="Transmission"
                  >
                    <DynamicHtml content={countryNotes.allSection.transmission} />
                  </RecordSection>
                )}
                {/* Incidence in humans */}
                {(countryNotes.allSection.incidenceInHumans ||
                  countryNotes.allSection.incidenceInHumansGraphs) && (
                  <RecordSection
                    link={sections[5]}
                    show={showHumanIncidence}
                    onCollapse={() => setShowHumanIncidence(!showHumanIncidence)}
                    accent={sections[5].accent}
                    id="Human Incidence"
                  >
                    {countryNotes.allSection.incidenceInHumans && (
                      <DynamicHtml content={countryNotes.allSection.incidenceInHumans} />
                    )}
                    {countryNotes.allSection.incidenceInHumansGraphs?.map((graph, index) => (
                      <DynamicHtml content={graph} key={index} />
                    ))}
                  </RecordSection>
                )}
                {/* Incidence in animals */}
                {(countryNotes.allSection.incidenceInAnimals ||
                  countryNotes.allSection.incidenceInAnimalsGraphs) && (
                  <RecordSection
                    link={sections[6]}
                    show={showAnimalIncidence}
                    onCollapse={() => setShowAnimalIncidence(!showAnimalIncidence)}
                    accent={sections[6].accent}
                    id="Animal Incidence"
                  >
                    {countryNotes.allSection.incidenceInAnimals && (
                      <DynamicHtml content={countryNotes.allSection.incidenceInAnimals} />
                    )}
                    {countryNotes.allSection.incidenceInAnimalsGraphs?.map((graph, index) => (
                      <DynamicHtml content={graph} key={index} />
                    ))}
                  </RecordSection>
                )}
                {/* Prevalence Surveys */}
                {(countryNotes.allSection.prevalence ||
                  countryNotes.allSection.prevalenceGraph) && (
                  <RecordSection
                    link={sections[7]}
                    show={showPrevalenceSurveys}
                    onCollapse={() => setShowPrevalenceSurveys(!showPrevalenceSurveys)}
                    accent={sections[7].accent}
                    id="prevalence"
                  >
                    {/* Prevalence Graph*/}
                    {countryNotes.allSection.prevalenceGraph?.map((graph, index) => (
                      <DynamicHtml content={graph} key={index} />
                    ))}
                    {/* Prevalence Table*/}
                    {countryNotes.allSection.prevalence?.map((survey, index) => (
                      <DynamicHtml content={survey} key={index} />
                    ))}
                  </RecordSection>
                )}
                {/* Seroprevalence Surveys */}
                {(countryNotes.allSection.seroprevalence ||
                  countryNotes.allSection.seroprevalenceGraph) && (
                  <RecordSection
                    link={sections[8]}
                    show={showSeroprevalenceSurveys}
                    onCollapse={() => setShowSeroprevalenceSurveys(!showSeroprevalenceSurveys)}
                    accent={sections[8].accent}
                    id="seroprevalence"
                  >
                    {/* Seroprevalence Graph*/}
                    {countryNotes.allSection.seroprevalenceGraph?.map((graph, index) => (
                      <DynamicHtml content={graph} key={index} />
                    ))}

                    {/* Seroprevalence Table*/}
                    {countryNotes.allSection.seroprevalence?.map((survey, index) => (
                      <DynamicHtml content={survey} key={index} />
                    ))}
                  </RecordSection>
                )}
                {/* Crossborder */}
                {countryNotes.allSection.crossborder && (
                  <RecordSection
                    link={sections[9]}
                    show={showCrossBorder}
                    onCollapse={() => setShowCrossBorder(!showCrossBorder)}
                    accent={sections[9].accent}
                    id="travel"
                  >
                    {sections[8].intro && <DynamicHtml content={sections[8].intro} />}
                    <DynamicHtml content={countryNotes.allSection.crossborder} />
                  </RecordSection>
                )}
                {/* Sporadic */}
                {countryNotes.allSection.sporadic && (
                  <RecordSection
                    link={sections[10]}
                    show={showSporadic}
                    onCollapse={() => setShowSporadic(!showSporadic)}
                    accent={sections[10].accent}
                    id="sporadic"
                  >
                    {sections[10].intro && <DynamicHtml content={sections[10].intro} />}
                    <DynamicHtml content={countryNotes.allSection.sporadic} />
                  </RecordSection>
                )}
                {/* Outbreaks */}
                {(countryNotes.allSection.outbreaks ||
                  countryNotes.allSection.outbreaks_intro ||
                  countryNotes.allSection.outbreakGraphs) && (
                  <RecordSection
                    link={sections[11]}
                    show={showOutbreaks}
                    onCollapse={() => setShowOutbreaks(!showOutbreaks)}
                    accent={sections[11].accent}
                    id="outbreaks"
                  >
                    {sections[11].intro && <DynamicHtml content={sections[11].intro} />}
                    {countryNotes.allSection.outbreakGraphs &&
                      countryNotes.allSection.outbreakGraphs.map((graph, index) => (
                        <DynamicHtml content={graph} key={index} />
                      ))}
                    {countryNotes.allSection.outbreaks && (
                      <DynamicHtml content={countryNotes.allSection.outbreaks} />
                    )}
                  </RecordSection>
                )}
                {/* Case series */}
                {countryNotes.allSection.case_series && (
                  <RecordSection
                    link={sections[12]}
                    show={showCaseSeries}
                    onCollapse={() => setShowCaseSeries(!showCaseSeries)}
                    accent={sections[12].accent}
                    id="caseseries"
                  >
                    {sections[12].intro && <DynamicHtml content={sections[12].intro} />}
                    <DynamicHtml content={countryNotes.allSection.case_series} />
                  </RecordSection>
                )}
                {/*Related Disease*/}
                {countryNotes.relatedDiseases && (
                  <RecordSection
                    link={sections[13]}
                    show={showRelatedDisease}
                    onCollapse={() => setShowRelatedDisease(!showRelatedDisease)}
                    accent={sections[13].accent}
                    id="Reviews"
                  >
                    {countryNotes.relatedDiseases.length > 0 && (
                      <ul>
                        {countryNotes.relatedDiseases.map(d => (
                          <li key={d.id}>
                            <Link to={`/explore/diseases/${d.id}/${countryId}`}>
                              {d.name} in {country.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </RecordSection>
                )}
                {/*Related Countries*/}
                {countryNotes.relatedCountries && (
                  <RecordSection
                    link={sections[14]}
                    show={showRelatedCountries}
                    onCollapse={() => setShowRelatedCountries(!showRelatedCountries)}
                    accent={sections[14].accent}
                    id="Reviews"
                  >
                    {countryNotes.relatedCountries.length > 0 && (
                      <ul>
                        {countryNotes.relatedCountries.map(d => (
                          <li key={d.id}>
                            <Link to={`/explore/diseases/${diseaseId}/${d.id}`}>
                              {disease} in {d.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </RecordSection>
                )}
                {/* Reviews and updates */}
                {countryNotes.allSection.reviews_and_updates && (
                  <RecordSection
                    link={sections[15]}
                    show={showReviewAndUpdates}
                    onCollapse={() => setShowReviewAndUpdates(!showReviewAndUpdates)}
                    accent={sections[15].accent}
                    id="Reviews"
                  >
                    {sections[15].intro && <DynamicHtml content={sections[15].intro} />}
                    <DynamicHtml content={countryNotes.allSection.reviews_and_updates} />
                  </RecordSection>
                )}
                <RecordSection
                  link={sections[sections.length - 1]}
                  show={showDownloads}
                  onCollapse={() => setShowDownloads(!showDownloads)}
                  accent={sections[sections.length - 1].accent}
                  id="disease-country-download"
                >
                  <DownloadsSection
                    id={`${diseaseId}/${countryId}`}
                    category="diseases"
                    labelTitle={title}
                  />
                </RecordSection>
              </>
            ) : (
              <NoDataTitle size="regular">
                GIDEON database has no data on {disease} in {country.name}
              </NoDataTitle>
            )}
          </RecordLayout>
        </ReferenceLabelContext.Provider>
      </MainLayout>
    </ReferenceProvider>
  );
};
