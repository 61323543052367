import { storeFactory } from 'store/storeFactory';
import { Action as SimpleAction, ActionWithPayload } from 'store/types';
import { ComparisonLists } from 'types/comparisonLists';

import { reducer } from './reducer';


export type ComparisonSection = Omit<State, 'activeSection'>;

export type ComparisonSectionKeys = keyof ComparisonSection;

type ComparePayload = {
  section: keyof ComparisonSection;
  id: number;
};

export type Action =
  | ActionWithPayload<'IrrelevantDisease/AddToList', ComparePayload>
  | ActionWithPayload<'IrrelevantDisease/RemoveFromList', ComparePayload>
  | ActionWithPayload<'IrrelevantDisease/ClearList', keyof State>
  | ActionWithPayload<'IrrelevantDisease/SetActiveSection', ComparisonSectionKeys>
  | SimpleAction<'IrrelevantDisease/Reset'>;

export type State = ComparisonLists & {
  activeSection: ComparisonSectionKeys | null;
};

export const defaultInitialState: State = {
  diseases: [],
  drugs: [],
  bacteria: [],
  mycobacteria: [],
  yeasts: [],
  activeSection: null,
};

export const {
  dispatchContext: IrrelevantItemsDispatchContext,
  stateContext: IrrelevantItemsStateContext,
  provider: IrrelevantItemsStoreProvider,
} = storeFactory(reducer, defaultInitialState);
