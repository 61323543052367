import { DeepLinkRedirectState } from 'Atoms/routes/AuthRoute';
import jwt_decode from 'jwt-decode';
import React, { FC, useLayoutEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { PromotionStateContext } from 'services/localStorage/PromotionState.provider';
import { useContextAssert } from 'services/useContextAssert.hook';
import { TokenPayload, useAccountController } from 'store/accountStore/hooks';

export const MagicLinkLogin: FC = () => {
  const { setToken, magicSignIn, setMagicToken } = useAccountController();
  const [isPromotion, setIsPromotion] = useContextAssert(PromotionStateContext);

  const location = useLocation<DeepLinkRedirectState | undefined>();

  const hash = location.pathname.split('/').at(-1) || '';
  const params = new URLSearchParams(location.search);

  const target = params.get('target') || '/';

  useLayoutEffect(() => {
    const handleMagicSignIn = async () => {
      try {
        const token = await magicSignIn(hash);
        if (token) {
          setIsPromotion(false);
          setToken(token);
          setMagicToken(token);
          const decodedToken = jwt_decode<TokenPayload>(token);
          if (decodedToken && decodedToken.user && decodedToken.user.promotion_name) {
            setIsPromotion(true);
          }
        }
      } catch (error: any) {
        setMagicToken('Expired');
      }
    };
    handleMagicSignIn();
  }, [setToken, hash, magicSignIn, setMagicToken]);

  return <Redirect to={target} />;
};
