import { P, Span } from 'Atoms/text';
import { DynamicHtml } from 'Organisms/dynamicContent/DynamicHtml';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { DynamicContent } from 'types/dynamicContent';

const Wrapper = styled.div`
  padding: 0 0 15px 0;
`;

const StyledP = styled(P)`
  margin-top: 20px;
`;

interface CustomCountryDetails {
  yellowFeverRequirements: DynamicContent | null;
  cdcRecommendation: DynamicContent | null;
  malariaGuideline: DynamicContent | null;
  chloroquineResistance?: DynamicContent | null;
  prophylaxisRecommendation?: DynamicContent | null;
}

interface Props {
  countryDetails: CustomCountryDetails;
  country: string;
}

export const CountryGuidelinesSection: FC<Props> = ({ countryDetails, country }) => {
  return (
    <Wrapper>
      {countryDetails.yellowFeverRequirements && (
        <>
          <P weight="600">Yellow fever guidelines for {country}</P>
          <StyledP weight="500">Official country requirements: </StyledP>
          <P>
            <DynamicHtml content={countryDetails.yellowFeverRequirements} />
          </P>
        </>
      )}

      {countryDetails.cdcRecommendation && (
        <>
          <StyledP weight="600">Recommendations</StyledP>
          <DynamicHtml content={countryDetails.cdcRecommendation} />
        </>
      )}

      {countryDetails.malariaGuideline && (
        <>
          <StyledP weight="600">Malaria guidelines for {country}</StyledP>
          <P>
            <Span weight="500">Areas of risk: </Span>
            <Span>
              <DynamicHtml content={countryDetails.malariaGuideline} />
            </Span>
          </P>
        </>
      )}

      {countryDetails.chloroquineResistance && (
        <>
          <StyledP weight="600">Chloroquine resistance</StyledP>
          <DynamicHtml content={countryDetails.chloroquineResistance} />
        </>
      )}

      {countryDetails.prophylaxisRecommendation && (
        <>
          <StyledP weight="600">Prophylaxis recommendations</StyledP>
          <DynamicHtml content={countryDetails.prophylaxisRecommendation} />
        </>
      )}
    </Wrapper>
  );
};
