import { ReactComponent as ArrowSvg } from 'assets/UI/ArrowRight.svg';
import { Button } from 'Atoms/buttons/Button';
import { Icon } from 'Atoms/Icon';
import React, { FC, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useTooltip } from 'services/useTooltip';
import styled from 'styled-components/macro';

interface Props {
  className?: string;
}

export const ToTopButton = styled(Button)`
  padding: 0;
  z-index: 100;

  width: 50px;
  height: 50px;

  border-radius: 50%;

  border: 1px solid;
  border-color: ${props => props.theme.colors.scrollToTopButton.border};
  background: ${props => props.theme.colors.scrollToTopButton.background};

  &:hover {
    border-color: ${props => props.theme.colors.scrollToTopButton.border};
    background: ${props => props.theme.colors.scrollToTopButton.background};
    ${Icon} {
      fill: ${props => props.theme.colors.scrollToTopButton.border};
    }
  }

  ${Icon} {
    fill: ${props => props.theme.colors.scrollToTopButton.border};
  }
`;

const IconStyled = styled(Icon)`
  transform: rotate(-90deg);
  width: 20px;
  height: 22px;
`;

const Container = styled.div``;

const isSamsungBrowser = (): boolean => /SamsungBrowser/.test(navigator.userAgent);

export const ScrollToTopButton: FC<Props> = ({ className }) => {
  const [showButton, setShowButton] = useState(false);
  const [props, popper, setElement] = useTooltip('Go to top', { position: 'top' });

  const match = useRouteMatch([
    '/start',
    '/explore/diseases',
    '/explore/drugs',
    '/explore/vaccines',
    '/explore/microbes',
    '/explore/countries',
    '/explore',
  ]);

  const onClick = (): void => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const onScroll = (): void => {
      if (document.body.scrollTop > 60 || document.documentElement.scrollTop > 60) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  if (!showButton || isSamsungBrowser() || match?.isExact) {
    return null;
  }

  return (
    <Container className={className} {...props.events}>
      <ToTopButton onClick={onClick} ref={setElement} ariaLabel="Scroll to top" {...props.aria}>
        <IconStyled svgComponent={ArrowSvg} />
      </ToTopButton>
      {popper}
    </Container>
  );
};
