import { ReactComponent as MaximizeSVG } from 'assets/UI/Maximize.svg';
import { ReactComponent as MinimizeSVG } from 'assets/UI/Minimize.svg';
import { ReactComponent as ResetSVG } from 'assets/UI/Reset.svg';
import { FilledButton } from 'Atoms/buttons/FilledButton';
import { Icon } from 'Atoms/Icon';
import { Loader } from 'Atoms/Loader';
import { SurveyTable } from 'Organisms/SurveyTable';
import React, { FC, useRef, useState } from 'react';
import { useFullscreen } from 'services/useFullscreen.hook';
import { useCountryOutbreaks } from 'store/countryNotesStore/hooks';
import { assertIsNotStoreError } from 'store/storeError';
import { isLoading } from 'store/types';
import styled from 'styled-components/macro';

const Container = styled.div`
  position: relative;

  &[data-fullscreen='true'] {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 30px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const StyledButton = styled(FilledButton)`
  max-width: 200px;
  padding: 10px;
  margin-right: 7px;

  &:last-child {
    margin-right: 0;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;
interface Props {
  diseaseId: number;
  countryId: string;
  title?: string;
}

const footnote1 = '* indicates publication year (not necessarily year of survey)';
const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
const OutbreaksColumns = [
  { name: 'Years', display: 'Years', show: true },
  { name: 'Region', display: 'Region', show: true },
  { name: 'Setting', display: 'Setting', show: false },
  { name: 'Population', display: 'Population', show: false },
  { name: 'Cases', display: 'Cases', show: true },
  { name: 'Deaths', display: 'Deaths', show: true },
  { name: 'Animal Population', display: 'Anim.Popul.', show: false },
  { name: 'Animal Cases', display: 'Anim.Cases', show: false },
  { name: 'Animal Deaths', display: 'Anim.Deaths', show: false },
  { name: 'Source', display: 'Source', show: false },
  { name: 'Pathogen', display: 'Pathogen', show: false },
  { name: 'Notes', display: 'Notes', show: true },
];

export const NotableOutbreaks: FC<Props> = ({ diseaseId, countryId, title }) => {
  const fullscreenRef = useRef<HTMLDivElement>(null);
  const { isFullscreenSupported, isFullscreenActive, toggleFullscreen } = useFullscreen(
    fullscreenRef
  );
  const [extraColumns, setExtraColumns] = useState(JSON.parse(JSON.stringify(OutbreaksColumns)));
  const addExtraColumn = (col: string): void => {
    const tempColumns = [...extraColumns];
    const index = tempColumns.findIndex(temp => temp.name === col);
    if (index > -1) {
      tempColumns[index].show = true;
      setExtraColumns(tempColumns);
    }
  };

  const outbreaksData = useCountryOutbreaks(diseaseId, countryId);
  assertIsNotStoreError(outbreaksData);
  if (isLoading(outbreaksData)) {
    return <Loader />;
  }

  if (!outbreaksData || outbreaksData.length === 0) {
    return <></>;
  }

  return (
    <Container ref={fullscreenRef} data-fullscreen={isFullscreenActive}>
      <SurveyTable
        tableType="outbreaks"
        outbreaksData={outbreaksData}
        footnote1={footnote1}
        title={title}
        extraColumns={extraColumns}
        addExtraColumn={addExtraColumn}
      />
      <ButtonContainer>
        {!isIOS && isFullscreenSupported && (
          <StyledButton onClick={() => toggleFullscreen()}>
            <StyledIcon
              svgComponent={isFullscreenActive ? MinimizeSVG : MaximizeSVG}
              size="small"
            />
            {isFullscreenActive ? 'Exit full screen' : 'View in full screen'}
          </StyledButton>
        )}
        <StyledButton onClick={() => setExtraColumns(JSON.parse(JSON.stringify(OutbreaksColumns)))}>
          <StyledIcon svgComponent={ResetSVG} size="small" />
          Reset table view
        </StyledButton>
      </ButtonContainer>
    </Container>
  );
};
